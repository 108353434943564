import React from 'react';
import css from './FilterButton.module.css';
import ChevronRight from '../../../../../icons/ChevronRight';

const FilterButton = React.forwardRef(({ name, count, onClick, ...rest }, ref) => {
  return (
    <button className={css.main} onClick={onClick} ref={ref} {...rest}>
      <span className={css.name}>{name}</span>
      <div className={css.count}>
        {count} <ChevronRight className={css.icon} />
      </div>
    </button>
  );
});

export default FilterButton;
