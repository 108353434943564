import React, { useCallback, useEffect, useState } from 'react';

import css from './RegulationDossier.module.css';

import useNavigation from '../../../hooks/useNavigation';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';
import LoadingState from './LoadingState/LoadingState';
import useRegulation from '../../../hooks/useRegulation';
import DossierAvatar from '../DossierAvatar/DossierAvatar';
import Compliant from '../../../../icons/Compliant';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import Source from '../../Source/Source';
import CompliantBadge from '../../CompliantBadge/CompliantBadge';
import DossierNotes from '../../DossierNotes/DossierNotes';
import { IdHash } from '../../../shared/dossier';
function RegulationDossier({ regulationId, size, onSizeChange }) {
  const {
    loading,
    regulation,
    notes,
    createNote,
    deleteNote,
    updateNote,
    isSavingNote,
    isUpdatingNote,
    isDeletingNote,
  } = useRegulation(regulationId);
  const { params, navigate, location } = useNavigation();

  const onPillClicked = (entityId) => {
    params.delete('regulationId');
    params.set('dossierEntityId', entityId);
    params.set('dossierType', 'entity');
    navigate(location.pathname, params, { back: 'Back to Regulation' }, IdHash.entity);
  };

  const scroll = useCallback((node) => {
    if (params.get('viewNotes') === 'true' && node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  });

  if (loading) return <LoadingState />;
  if (!regulation) return null;

  return (
    <div className={css.main}>
      <div className={css.header}>
        <DossierHeader size={size} onSizeChange={onSizeChange} />
        <div
          style={{ marginTop: -5, zIndex: 5, position: 'relative' }}
          className={css.divider}
        />
      </div>
      <div className={css.content}>
        <DossierAvatar>
          <div className={css.icon}>
            <Compliant />
          </div>
        </DossierAvatar>

        <h3 className={css.name}>{regulation.summarizedName}</h3>

        <div data-expanded={size === 'expanded'} className={css.typeDate}>
          <div className={css.item}>
            <div className={css.itemTitle}>Type</div>
            <div className={css.itemContent}>{regulation.regulationType}</div>
          </div>

          <div className={css.item}>
            <div className={css.itemTitle}>Issued By</div>
            <div className={`${css.itemContent} ${css.pill}`}>
              <EntityPill
                image={regulation.issuer.image}
                name={regulation.issuer.name}
                shortName={regulation.issuer.shortestName}
                type={regulation.issuer.type}
                isConnected={regulation.issuer.isConnected}
                inSubscription={regulation.issuer.inSubscription}
                onClick={() => onPillClicked(regulation.issuer.id)}
                tooltip={regulation.issuer.tooltip}
                size="small"
              />
            </div>
          </div>

          {regulation.authorizedIssuer && (
            <div className={css.item}>
              <div className={css.itemTitle}>Relevant Individual</div>
              <div className={`${css.itemContent} ${css.pill}`}>
                <EntityPill
                  image={regulation.authorizedIssuer.image}
                  name={regulation.authorizedIssuer.name}
                  shortName={regulation.authorizedIssuer.shortestName}
                  type={regulation.authorizedIssuer.type}
                  isConnected={regulation.authorizedIssuer.isConnected}
                  inSubscription={regulation.authorizedIssuer.inSubscription}
                  tooltip={regulation.authorizedIssuer.tooltip}
                  onClick={() => onPillClicked(regulation.authorizedIssuer.id)}
                  size="small"
                />
              </div>
            </div>
          )}

          <div className={css.item}>
            <div className={css.itemTitle}>Industries</div>
            <div className={css.itemContent}>
              <div className={css.list}>
                {regulation.industries.map((industry) => (
                  <BriefPill
                    key={industry.id}
                    name={industry.name}
                    tooltip={industry.tooltip}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={css.item}>
            <div className={css.itemTitle}>Jurisdiction</div>
            <div className={css.itemContent}>
              <BriefPill name={regulation.jurisdiction} isGeography />
            </div>
          </div>

          <div className={css.item}>
            <div className={css.itemTitle}>Legal Name</div>
            <div className={css.itemContent}>{regulation.legalName}</div>
          </div>

          <div className={css.item}>
            <div className={css.itemTitle}>Source</div>
            <div className={css.itemContent}>
              <div className={css.sources}>
                <Source src={regulation.source} />
                <Source src={regulation.attachment} />
              </div>
            </div>
          </div>
        </div>

        <div className={css.buttons}>
          {regulation.isCompliant && <CompliantBadge />}
        </div>
      </div>
      <div className={css.divider} />
      <div className={css.summaryCard}>
        <div className={css.summaryCardTitle}>Summary</div>
        <div className={css.summaryCardContent}>{regulation.summary}</div>
        <div className={css.summaryCardContent}>
          <ul>
            {regulation.bullets.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={css.divider} ref={scroll} />
      <DossierNotes
        notes={notes}
        saveNote={(note) => createNote(regulationId, note)}
        onDeleteNote={deleteNote}
        onEditNote={updateNote}
        isSavingNote={isSavingNote}
        isUpdatingNote={isUpdatingNote}
        isDeletingNote={isDeletingNote}
      />
    </div>
  );
}

export default function RegulationDossierWrapper({ size, onSizeChange }) {
  const [isDossierOpen, setIsDossierOpen] = useState(false);
  const { params } = useNavigation();

  useEffect(() => {
    const regulationId = params.get('regulationId');
    const dossierType = params.get('dossierType');

    if (regulationId && dossierType) {
      setIsDossierOpen(true);
    } else {
      setIsDossierOpen(false);
    }
  }, [params]);

  if (!isDossierOpen) return null;

  return (
    <RegulationDossier
      size={size}
      regulationId={params.get('regulationId')}
      onSizeChange={onSizeChange}
    />
  );
}
