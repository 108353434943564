import React, { useRef, useState } from 'react';

import css from './EntityDossier.module.css';
import useNavigation from '../../../hooks/useNavigation';
import { DossierType, UrlParam } from '../../../shared/dossier';
import useEntity from '../../../hooks/useEntity';
import NotesDossier from './NotesDossier/NotesDossier';
import AbstractErrorBundary from '../../AbstractErrorBundary/AbstractErrorBundary';
import EntityDossierError from './EntityDossierError';
import Header from './Header/Header';
import Body from './Body/Body';

function EntityDossier({ entityId, size }) {
  const [showNotesDossier, setShowNotesDossier] = useState(false);

  const dossierRef = useRef();
  const {
    entity,
    isLoading,
    toggleBookmark,
    toggleUserConnection,
    onNoteAdded,
  } = useEntity(entityId);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <div className={css.main} ref={dossierRef}>
      {!showNotesDossier && (
        <>
          <Header isLoading={isLoading} entity={entity} />
          <Body
            size={size}
            entity={entity}
            isLoading={isLoading}
            toggleBookmark={toggleBookmark}
            toggleUserConnection={toggleUserConnection}
            showNotesDossier={() => setShowNotesDossier(true)}
          />
        </>
      )}

      {showNotesDossier && (
        <NotesDossier
          entity={entity}
          onCancel={() => setShowNotesDossier(false)}
          size={size}
          onNoteAdded={onNoteAdded}
        />
      )}
    </div>
  );
}

function LoadingState() {
  return (
    <div className={css.loadingState}>
      <div style={{ height: 360 }} className="skeleton-v2" />
      <div style={{ height: 34 }} className="skeleton-v2" />
      <div style={{ height: 46 }} className="skeleton-v2" />
      <div style={{ height: 122 }} className="skeleton-v2" />
      <div style={{ height: 122 }} className="skeleton-v2" />
      <div style={{ display: 'flex', gap: 16 }}>
        <div style={{ height: 100, flex: 1 }} className="skeleton-v2" />
        <div style={{ height: 100, flex: 1 }} className="skeleton-v2" />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <div style={{ height: 100, flex: 1 }} className="skeleton-v2" />
        <div style={{ height: 100, flex: 1 }} className="skeleton-v2" />
      </div>
      <div style={{ height: 100 }} className="skeleton-v2" />
      <div style={{ height: 100 }} className="skeleton-v2" />
    </div>
  );
}

function EntityDossierWrapper({ onSizeChange, size }) {
  const { params } = useNavigation();

  const entityId = params.get(UrlParam.entityId);
  const dossierType = params.get(DossierType.DossierType);

  if (!entityId || !dossierType || dossierType !== DossierType.Entity)
    return null;

  return (
    <AbstractErrorBundary fallback={<EntityDossierError />}>
      <EntityDossier
        size={size}
        entityId={entityId}
        onSizeChange={onSizeChange}
      />
    </AbstractErrorBundary>
  );
}

export default EntityDossierWrapper;
