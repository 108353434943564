import { gql } from '@apollo/client';

const query = gql`
  query getDossierEntity($id: ID!) {
    getDossierEntity(id: $id) {
      id
      name
      type
      image
      roles {
        org
        type
        startDate
        endDate
        primary
        source
        position
        employerEntity {
          id
          name
          image
          type
          subType
          shortestName
          topics
          isConnected
          inSubscription
        }
      }
      hidden
      topics
      subType
      lifespan {
        age
        start
        end
      }
      locations {
        date
        name
        primary
        sources
        location {
          lat
          lng
          name
          zoom
          address
        }
        detail
      }
      educations {
        degree
        date
        startDate
        endDate
        sources
        location {
          lat
          lng
          name
          zoom
          address
        }
        specialty
        institution
        institutionEntity {
          id
          name
          image
          type
          subType
          shortestName
          topics
          inSubscription
          isConnected
        }
      }
      shortestName
      alternateNames
      onlinePresence {
        platform
        url
      }
      primaryTopicId
      inSubscription
      isBookmarked
      primaryRoleTitle
      isConnected
      connectionNote
      notesCount
      bio
      developments
      industries {
        id
        name
      }
      countries {
        id
        name
      }
    }
  }
`;

export default query;
