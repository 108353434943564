import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniqueId } from 'lodash';

import { useAxisData } from '../../../../contexts/AxisDataContext';
import GET_NEWS from '../../../../queries/getEvents';
import SmallEventCard from '../../../components/Cards/SmallEventCard/SmallEventCard';
import useNavigation from '../../../hooks/useNavigation';
import { ROUTES } from '../../../shared/routes';
import css from './News.module.css';

function News({
  countryId,
  industryId,
  counts,
  startDate,
  endDate,
  filteredEvents,
  isLoadingFilteredEvents,
  className = '',
  ...rest
}) {
  const { navigate } = useNavigation();
  const { flatIndustries, topics } = useAxisData();

  const [events, setEvents] = useState([]);
  const [variables, setVariables] = useState({
    filterCategories: {
      events: {
        eventFilters: ['news'],
        limit: 40,
        nextToken: null,
      },
    },
    topics: [countryId],
    filterIndustries: [industryId],
    endDate: new Date(endDate),
    startDate: new Date(startDate),
  });

  const { loading } = useQuery(GET_NEWS, {
    variables,
    onCompleted: (data) => {
      setEvents(data.searchEventsAndRegulations.events.results || []);
    },
  });

  useEffect(() => {
    setVariables((current) => {
      return {
        ...current,
        topics: [countryId],
        filterIndustries: [industryId],
        endDate: new Date(endDate),
        startDate: new Date(startDate),
      };
    });
  }, [industryId, countryId, startDate, endDate]);

  const handleSeeAll = () => {
    const industry = flatIndustries.find(({ id }) => id === industryId);
    const country = topics.find(({ id }) => id === countryId);

    const params = new URLSearchParams();
    const uuid = uniqueId('search-');
    params.set('queryId', uuid);
    params.set('isFilterOpen', true);
    sessionStorage.setItem(
      uuid,
      JSON.stringify({
        industries: [industry],
        countries: [country],
        dates: [
          new Date(startDate).toISOString(),
          new Date(endDate).toISOString(),
        ],
        searchTypes: 'event',
        eventTypes: ['news'],
      }),
    );

    navigate(ROUTES.ADV_SEARCH, params);
  };

  const count = useMemo(() => {
    if (filteredEvents) return filteredEvents.length;

    if (!counts) return '';

    return counts.find(({ type }) => type === 'news').total || '';
  }, [filteredEvents, counts]);

  const isLoading = isLoadingFilteredEvents || loading;
  const isEmpty = events.length === 0 && !isLoading;
  const listNews = filteredEvents || events;

  return (
    <div className={`${css.main} ${className}`} {...rest}>
      <div className={css.title}>
        News
        <span>{count}</span>
        {listNews?.length > 0 && (
          <button type="button" className={css.seeAll} onClick={handleSeeAll}>
            See All
          </button>
        )}
      </div>

      <div className={css.list}>
        {isLoading ? (
          <LoadingState />
        ) : (
          listNews.map((event) => (
            <SmallEventCard key={event.id} event={event} />
          ))
        )}

        {isEmpty && (
          <div className={css.emptyState}>No events for this period.</div>
        )}
      </div>
    </div>
  );
}

export default News;

function LoadingState() {
  return (
    <div className={css.loadingContainer}>
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
    </div>
  );
}
