import React, { useMemo } from 'react';

import useNavigation from '../../../hooks/useNavigation';
import useSortedTags from '../../../hooks/useSortedTags';

import { BaseCard } from '../BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import { formatDateMed } from '../../../../lib/date';
import Score from '../../Score/Score';
import { calcImportanceScore } from '../../../shared/events';

import css from './AppointmentCard.module.css';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import NotesOutline from '../../../../icons/NotesOutline';

function AppointmentCard({ appointment }) {
  const { toggleAppointmentDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(appointment.magnitude, appointment.sentiment),
    [appointment],
  );

  const { industries, countries } = useSortedTags(appointment);

  return (
    <BaseCard.Root>
      <BaseCard.Row>
        <BaseCard.LeftColumn>
          <BaseCard.DateRow
            date={appointment.startDate}
            before={<Score score={score} />}
          />
          <BaseCard.Title
            onClick={() => toggleAppointmentDossier(appointment.id)}
          >
            {appointment.headline}
          </BaseCard.Title>
        </BaseCard.LeftColumn>

        <BaseCard.RightColumn>
          <BaseCard.InfoColumn title="Person">
            <EntityPill
              id={appointment.entity.id}
              image={appointment.entity.image}
              name={appointment.entity.name}
              shortName={appointment.entity.shortestName}
              tooltip={appointment.entity.tooltip}
              type={appointment.entity.type}
              isConnected={appointment.entity.isConnected}
              inSubscription={appointment.entity.inSubscription}
              autoOpenDossier
              size="small"
            />
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Title">
            {appointment.title}
          </BaseCard.InfoColumn>

          {appointment.organization && !appointment.organizationEntity && (
            <BaseCard.InfoColumn title="Organization">
              {appointment.organization}
            </BaseCard.InfoColumn>
          )}

          {appointment.organizationEntity && (
            <BaseCard.InfoColumn title="Organization">
              <EntityPill
                id={appointment.organizationEntity.id}
                image={appointment.organizationEntity.image}
                name={appointment.organizationEntity.name}
                shortName={appointment.organizationEntity.shortestName}
                tooltip={appointment.organizationEntity.tooltip}
                type={appointment.organizationEntity.type}
                isConnected={appointment.organizationEntity.isConnected}
                inSubscription={appointment.organizationEntity.inSubscription}
                autoOpenDossier
                size="small"
              />
            </BaseCard.InfoColumn>
          )}

          <BaseCard.InfoColumn title="Start Date">
            {formatDateMed(appointment.startDate)}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Industries">
            {industries.map((industry) => (
              <BriefPill
                key={industry.id}
                name={industry.name}
                relevance={industry.industryRelevance}
              />
            ))}
          </BaseCard.InfoColumn>

          <BaseCard.InfoColumn title="Countries">
            {countries.map((country) => (
              <BriefPill
                key={country.id}
                name={country.name}
                relevance={country.locationRelevance}
                isGeography
              />
            ))}
          </BaseCard.InfoColumn>
        </BaseCard.RightColumn>
      </BaseCard.Row>

      <BaseCard.BottomRow className={css.footer}>
        <div className={css.buttons}>
          <button
            type="button"
            className={css.button}
            onClick={() => toggleAppointmentDossier(appointment.id)}
          >
            <NotesOutline />
            Add Note
          </button>
        </div>
        <BaseCard.CardSources sources={[appointment.source]} />
      </BaseCard.BottomRow>
    </BaseCard.Root>
  );
}

export default AppointmentCard;
