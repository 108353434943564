export const DossierType = {
  DossierType: 'dossierType',
  Entity: 'entity',
  Event: 'eventDetails',
  Regulation: 'regulationDetails',
  Appointment: 'appointmentDetails',
  Quote: 'quoteDetails',
};

export const UrlParam = {
  eventId: 'dossierEventId',
  regulationId: 'regulationId',
  entityId: 'dossierEntityId',
  appointmentId: 'dossierAppointmentId',
  quoteId: 'dossierQuoteId',
  noteId: 'noteId',
  viewQuote: 'viewQuote',
  viewNotes: 'viewNotes',
};

export const IdHash = {
  content: 'content',
  dossier: 'dossier',
  event: 'dossier-event',
  quote: 'dossier-quote',
  entity: 'dossier-entity',
  regulation: 'dossier-regulation',
  appointment: 'dossier-appointment',
  relationship: 'dossier-relationship',
  search: 'search',
  leftPanel: 'left-panel',
};
