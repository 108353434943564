import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import css from './App.module.css';

import ErrorDetail from '../../contexts/ErrorDetail/ErrorDetail';
import { IdentityContext } from '../../contexts/IdentityContext';
import { RouteInformationContext } from '../../contexts/RouteInformationContext';
import { AxisDataContext } from '../../contexts/AxisDataContext';

import Login from '../../pages/Login/Login';
import AxisApp from '../AxisApp/AxisApp';

import 'react-toastify/dist/ReactToastify.css';
import { ModalContext } from '../../contexts/ModalContext';
import { SharedStateContext } from '../../contexts/SharedStateContext';
import { ROUTES } from '../../v2/shared/routes';

const App = () => {
  return (
    <IdentityContext>
      <SharedStateContext>
        <AxisDataContext>
          <RouteInformationContext>
            <ErrorDetail>
              <ModalContext>
                <Switch>
                  <Route path={ROUTES.LOGIN} component={Login} />
                  <Route>
                    <AxisApp />
                  </Route>
                </Switch>
                <ToastContainer
                  bodyClassName={css.toastBody}
                  toastClassName={css.toastClassName}
                  closeButton={null}
                />
              </ModalContext>
            </ErrorDetail>
          </RouteInformationContext>
        </AxisDataContext>
      </SharedStateContext>
    </IdentityContext>
  );
};

export default App;
