import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { DossierType, UrlParam, IdHash } from '../shared/dossier';

function useNavigation() {
  const history = useHistory();
  const location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  /** Push a single param */
  const pushParam = (paramName, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramName, value);
    history.push({ search: searchParams.toString() });
  };

  /** Delete a single param */
  const deleteParam = (paramName) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramName);
    history.push({ search: searchParams.toString() });
  };

  const replaceParams = (searchParams, hash) => {
    const state = { search: searchParams.toString() };
    if (hash) {
      state.hash = hash;
      window.location.hash = hash;
    }
    history.push(state);
  };

  const navigate = (path, searchParams, state = {}, hash = '') => {
    window.location.hash = hash;
    history.push({
      pathname: path,
      search: searchParams.toString(),
      hash,
      state,
    });
  };

  const deleteSecundaryParams = () => {
    params.delete(UrlParam.regulationId);
    params.delete(UrlParam.entityId);
    params.delete(UrlParam.eventId);
    params.delete(UrlParam.noteId);
    params.delete(UrlParam.appointmentId);
    params.delete(UrlParam.quoteId);
  };

  const toggleEntityDossier = (entityId) => {
    deleteSecundaryParams();
    params.set(UrlParam.entityId, entityId);
    params.set(DossierType.DossierType, DossierType.Entity);
    replaceParams(params, IdHash.entity);
  };

  const toggleRegulationDossier = (regulationId, noteId, viewNotes = false) => {
    deleteSecundaryParams();
    params.set(UrlParam.regulationId, regulationId);
    params.set(DossierType.DossierType, DossierType.Regulation);
    if (noteId) {
      params.set(UrlParam.noteId, noteId);
    }
    params.set(UrlParam.viewNotes, viewNotes);
    replaceParams(params, IdHash.regulation);
  };

  const toggleEventDossier = (
    eventId,
    viewQuote = false,
    viewNotes = false,
  ) => {
    deleteSecundaryParams();
    params.set(UrlParam.eventId, eventId);
    params.set(DossierType.DossierType, DossierType.Event);
    params.set(UrlParam.viewQuote, viewQuote);
    params.set(UrlParam.viewNotes, viewNotes);
    replaceParams(params, IdHash.event);
  };

  const toggleAppointmentDossier = (appointmentId) => {
    deleteSecundaryParams();
    params.set(UrlParam.appointmentId, appointmentId);
    params.set(DossierType.DossierType, DossierType.Appointment);
    replaceParams(params, IdHash.appointment);
  };

  const toggleQuoteDossier = (quoteId, viewNotes = false) => {
    deleteSecundaryParams();
    params.set(UrlParam.quoteId, quoteId);
    params.set(DossierType.DossierType, DossierType.Quote);
    params.set(UrlParam.viewNotes, viewNotes);
    replaceParams(params, IdHash.quote);
  };

  return {
    pushParam,
    deleteParam,
    replaceParams,
    navigate,
    toggleEntityDossier,
    toggleRegulationDossier,
    toggleEventDossier,
    toggleAppointmentDossier,
    toggleQuoteDossier,
    params,
    location,
  };
}

export default useNavigation;
