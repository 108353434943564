import React, { useState } from 'react';
import { useFeed } from '../../Feed';

import css from './FeedHeader.module.css';
import SearchIcon from '../../../../icons/Search';
import SavedSearchModal from '../../../../components/SavedSearchModal/SavedSearchModal';
import updateSavedSearch from '../../../../queries/updateSaveSearch';
import createSavedSearch from '../../../../queries/createSaveSearch';
import { useApolloClient } from '../../../../hooks/useApolloClient';
import notify from '../../../../lib/notify';
import HomeIcon from '../../../../icons/Home';

const updateSavedSearchMutation = async ({
  client,
  savedSearchId,
  updatedName,
  updatedData,
}) => {
  return client
    .mutate({
      mutation: updateSavedSearch,
      variables: { savedSearchId, updatedName, updatedData },
    })
    .then(({ data }) => data.updateSavedSearch);
};

const createSavedSearchMutation = async ({
  client,
  searchName,
  searchData,
}) => {
  return client
    .mutate({
      mutation: createSavedSearch,
      variables: { searchName, searchData },
    })
    .then(({ data }) => data.createSavedSearch);
};

const getFilterTypeDescription = (type) => {
  if (type === 'all') return 'all results';
  if (type === 'event') return 'events';
  if (type === 'regulation') return 'regulations';
  return type;
};

const ResultHeaderHighlight = ({ className = '', text, children }) => (
  <span className={`${className} ${css.titleHighlight}`}>
    {text}
    {children}
  </span>
);

const SearchHeaderGroup = ({ results, summaryText, formatResult }) => {
  if (results.length === 1) {
    return <ResultHeaderHighlight text={formatResult(results[0])} />;
  }

  return (
    <ResultHeaderHighlight className={css.titleFilters} text={summaryText}>
      <div className={css.titleFiltersPopover}>
        {results.map((result) => (
          <ResultHeaderHighlight
            key={result.id}
            className={css.titlePopoverHighlight}
            text={formatResult(result)}
          />
        ))}
      </div>
    </ResultHeaderHighlight>
  );
};

const FeedHeader = ({ children }) => {
  const {
    feedResultType,
    feedIndustries,
    feedCountries,
    feedEntities,
    feedLandscapes,
    savedSearchName,
    homeFeedSavedSearch,
    refreshSavedSearches,

    setRefreshSavedSearches,
    clearSavedSearchFilter,
    savedSearchId,
    searchDates,
  } = useFeed();

  const client = useApolloClient();

  const [openSaveSearchModal, setOpenSaveSearchModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [updateType, setUpdateType] = useState('setHomeFeed');

  const showIndustries = feedIndustries.length > 0;
  const showCountries = feedCountries.length > 0;
  const showEntity = !!feedEntities.length;

  const handleSaveSearch = async (payload) => {
    let result;

    console.log('savedSearchId', savedSearchId);
    if (savedSearchId) {
      result = await updateSavedSearchMutation({
        client,
        savedSearchId,
        updatedName: searchName || savedSearchName,
        updatedData: JSON.stringify(payload),
      });
    } else {
      result = await createSavedSearchMutation({
        client,
        searchName: searchName || '',
        searchData: JSON.stringify(payload),
      });
    }

    setOpenSaveSearchModal(false);
    if (result) {
      setRefreshSavedSearches(!refreshSavedSearches);
      notify.success(
        homeFeedSavedSearch
          ? 'Saved search has been removed as home feed!'
          : 'Saved search has been set as home feed!',
        {
          position: 'bottom-right',
        },
      );
    } else {
      notify.error('Something went wrong!', { position: 'bottom-right' });
    }
  };

  const handleOpenSaveSearchModal = (type) => {
    setSearchName(savedSearchName);
    setUpdateType(type);
    setOpenSaveSearchModal(true);
  };

  return (
    <div className={css.main} data-cy="feed-header">
      {children}
      <div className={css.summary}>
        Viewing
        {savedSearchName ? (
          <span>
            {' '}
            saved search{' '}
            <span className={css.savedSearch}>
              <SearchIcon className={css.savedSearchIcon} />
              {savedSearchName}
            </span>
          </span>
        ) : (
          <span>
            <ResultHeaderHighlight
              text={getFilterTypeDescription(feedResultType)}
            />
            {showCountries && (
              <>
                in
                <SearchHeaderGroup
                  results={feedCountries}
                  summaryText={`${feedCountries.length} countries`}
                  formatResult={(country) => country.name}
                />
              </>
            )}
            {showIndustries && (
              <>
                {showCountries ? 'and' : 'in'}
                <SearchHeaderGroup
                  results={feedIndustries}
                  summaryText={`${feedIndustries.length} industries`}
                  formatResult={(country) => country.name}
                />
              </>
            )}
            {showEntity && (
              <>
                related to
                {feedEntities.map((feedEntity, i) => (
                  <ResultHeaderHighlight text={feedEntity.name} key={i} />
                ))}
              </>
            )}
          </span>
        )}
      </div>
      {(savedSearchName ||
        showCountries ||
        showIndustries ||
        showEntity ||
        (feedResultType && feedResultType !== 'all')) && (
        <div className={css.saveSearchContainer}>
          <button
            data-cy="save-search-button"
            type="button"
            className={css.backButton}
            onClick={() => clearSavedSearchFilter()}
          >
            ← Back to feed
          </button>
          <button
            data-cy="save-search-button"
            type="button"
            className={css.setAsHomeFeed}
            data-status={homeFeedSavedSearch ? 'saved' : 'default'}
            onClick={() =>
              handleOpenSaveSearchModal(
                homeFeedSavedSearch ? 'removeHomeFeed' : 'setHomeFeed',
              )
            }
          >
            <span
              className={css.homeFeed}
              data-feed-action={homeFeedSavedSearch ? 'remove' : 'set'}
            >
              <HomeIcon
                className={css.homeIcon}
                color={homeFeedSavedSearch ? '#cc0909' : ''}
              />{' '}
              {homeFeedSavedSearch ? 'Remove as home feed' : 'Set as home feed'}
            </span>
          </button>
        </div>
      )}
      <SavedSearchModal
        activeSearch=""
        openSaveSearchModal={openSaveSearchModal}
        saveSearchName={searchName}
        handleSaveSearchNameChange={(event) =>
          setSearchName(event.target.value)
        }
        handleSaveSearch={handleSaveSearch}
        handleCloseSaveSearchModal={() => setOpenSaveSearchModal(false)}
        searchCountries={feedCountries}
        searchTypes={feedResultType ? [feedResultType] : []}
        searchIndustries={feedIndustries}
        searchEntities={feedEntities}
        searchDates={searchDates}
        searchLandscapes={feedLandscapes}
        updateType={updateType}
      />
    </div>
  );
};

export default FeedHeader;
