import React, { useEffect, useMemo, useState, useCallback } from 'react';

import css from './EventDossier.module.css';
import useNavigation from '../../../hooks/useNavigation';
import useEvent from '../../../hooks/useEvent';
import useSortedTags from '../../../hooks/useSortedTags';
import LoadingState from '../RegulationDossier/LoadingState/LoadingState';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';
import DossierAvatar from '../DossierAvatar/DossierAvatar';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import Event from '../../../../icons/Event';
import { formatDateMed } from '../../../../lib/date';
import Score from '../../Score/Score';
import { calcImportanceScore } from '../../../shared/events';
import { IdHash } from '../../../shared/dossier';
import DossierCard from '../EntityDossier/Body/DossierCard/DossierCard';
import QuoteCard from '../../Cards/QuoteCard/QuoteCard';
import DossierNotes from '../../DossierNotes/DossierNotes';
import { BaseCard } from '../../Cards/BaseCard/BaseCard';

function EventDossier({ eventId, size, onSizeChange }) {
  const {
    loading,
    event,
    notes,
    createNote,
    updateNote,
    deleteNote,
    isSavingNote,
    isUpdatingNote,
    isDeletingNote,
  } = useEvent(eventId);
  const { params, navigate, location } = useNavigation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleQuotes, setVisibleQuotes] = useState([]);

  const scroll = useCallback((node) => {
    if (params.get('viewQuote') === 'true' && node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const scrollNotes = useCallback((node) => {
    if (params.get('viewNotes') === 'true' && node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const onPillClicked = (entity) => {
    params.delete('dossierEventId');
    params.delete('viewQuote');
    params.set('dossierEntityId', entity.id);
    params.set('dossierType', 'entity');
    navigate(location.pathname, params, { back: 'Back to Event' }, IdHash.entity);
  };

  const score = useMemo(() => {
    if (!event) return 0;
    setVisibleQuotes(event.quotes.slice(0, 1));
    return calcImportanceScore(event.magnitude, event.sentiment);
  }, [event]);

  const { industries, countries } = useSortedTags(
    event && {
      industries: event.briefs,
      countries: event.countries,
    },
  );

  if (loading) return <LoadingState />;
  if (!event) return null;

  const onNextPage = () => {
    setCurrentIndex((prev) => prev + 1);
    setVisibleQuotes(event.quotes.slice(currentIndex + 1, currentIndex + 2));
  };

  const onPreviousPage = () => {
    setCurrentIndex((prev) => prev - 1);
    setVisibleQuotes(event.quotes.slice(currentIndex - 1, currentIndex));
  };

  const pagination = {
    totalItems: 1,
    totalPages: event?.quotes?.length ?? 0,
    currentIndex,
    onNextPage,
    onPreviousPage,
  };

  return (
    <div className={css.main}>
      <div className={css.header}>
        <DossierHeader size={size} onSizeChange={onSizeChange} />
        <div
          style={{ marginTop: -5, zIndex: 5, position: 'relative' }}
          className={css.divider}
        />
      </div>
      <div className={css.content}>
        <DossierAvatar>
          <div className={css.icon}>
            <Event />
          </div>
        </DossierAvatar>

        <h3 className={css.name}>{event.title}</h3>

        <div className={css.item}>
          <div className={css.itemTitle}>Date</div>
          <div className={css.itemContent}>{formatDateMed(event.date)}</div>
        </div>

        <div className={css.item}>
          <div className={css.itemTitle}>Business Relevance</div>
          <div className={css.itemContent}>
            <Score score={score} hideText customStyles={{ marginTop: '4px' }} />
          </div>
        </div>

        <div className={css.item}>
          <div className={css.itemTitle}>Entities</div>
          <div className={`${css.itemContent} ${css.pill}`}>
            <div className={css.entities}>
              {event.entities?.map((entity) => (
                <EntityPill
                  key={entity.id}
                  image={entity.image}
                  name={entity.name}
                  shortName={entity.shortestName}
                  type={entity.type}
                  className={css.pill}
                  isConnected={entity.isConnected}
                  inSubscription={entity.inSubscription}
                  tooltip={entity.tooltip}
                  onClick={() => onPillClicked(entity)}
                  size="small"
                />
              ))}
            </div>
          </div>
        </div>

        {industries.length > 0 && (
          <div className={css.item}>
            <div className={css.itemTitle}>Industries</div>
            <div className={css.itemContent}>
              <div className={css.list}>
                {industries.map((brief) => (
                  <BriefPill
                    key={brief.id}
                    name={brief.name}
                    tooltip={brief.tooltip}
                    relevance={brief.industryRelevance}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {countries.length > 0 && (
          <div className={css.item}>
            <div className={css.itemTitle}>Countries</div>
            <div className={css.itemContent}>
              <div className={css.list}>
                {countries.map((country) => (
                  <BriefPill
                    key={country.id}
                    name={country.name}
                    relevance={country.locationRelevance}
                    isGeography
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        <div className={css.item}>
          <div className={css.itemTitle}>Sources</div>
          <div className={css.itemContent}>
            <BaseCard.CardSources sources={event.sources} />
          </div>
        </div>
      </div>
      <div className={css.divider} />

      {event.summary && (
        <div className={css.summaryCard}>
          <div className={css.summaryCardTitle}>Summary</div>
          <div className={css.summaryCardContent}>{event.summary}</div>
          <div className={css.summaryCardContent}>
            <ul>
              {event.bullets?.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {visibleQuotes.length > 0 && (
        <DossierCard
          title=""
          data-cy="quote-facet"
          className={css.quoteCard}
          pagination={pagination}
        >
          <div
            className={css.list}
            data-expanded={size === 'small'}
            ref={scroll}
          >
            {visibleQuotes.map((quote) => (
              <QuoteCard
                mode={size === 'normal' ? 'small-dossier' : 'expanded-dossier'}
                quote={{ ...quote, event }}
                key={quote.id}
              />
            ))}
          </div>
        </DossierCard>
      )}
      <div className={css.divider} ref={scrollNotes} />

      <DossierNotes
        notes={notes}
        saveNote={(note) => createNote(eventId, note)}
        onDeleteNote={deleteNote}
        onEditNote={updateNote}
        isSavingNote={isSavingNote}
        isUpdatingNote={isUpdatingNote}
        isDeletingNote={isDeletingNote}
      />
    </div>
  );
}

export default function EventDossierWrapper({ size, onSizeChange }) {
  const [isDossierOpen, setIsDossierOpen] = useState(false);
  const { params } = useNavigation();

  useEffect(() => {
    const dossierEventId = params.get('dossierEventId');
    const dossierType = params.get('dossierType');

    if (dossierEventId && dossierType) {
      setIsDossierOpen(true);
    } else {
      setIsDossierOpen(false);
    }
  }, [params]);

  if (!isDossierOpen) return null;

  return (
    <EventDossier
      size={size}
      eventId={params.get('dossierEventId')}
      onSizeChange={onSizeChange}
    />
  );
}
