import React from 'react';

import css from './IconButton.module.css';

const IconButton = React.forwardRef((props, ref) => {
  const { children, size = 'small', ...rest } = props;

  return (
    <button
      data-type="iconButton"
      type="button"
      ref={ref}
      data-size={size}
      className={css.button}
      {...rest}
    >
      {children}
    </button>
  );
});
export default IconButton;
