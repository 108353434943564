import React from 'react';

import { Popover } from 'react-tiny-popover';
import css from './FilterPopUp.module.css';

function FilterPopUp({
  isPopoverOpen,
  onClickOutside,
  openAtBottom = false,
  content,
  children,
}) {
  const popoverProps = openAtBottom
    ? {
        positions: ['bottom', 'right', 'left', 'top'],
        align: 'start',
        containerStyle: { paddingInline: 10 },
      }
    : {
        positions: ['right', 'left'],
        align: 'center',
        containerStyle: { paddingBlock: 10 },
      };

  return (
    <Popover
      {...popoverProps}
      isOpen={isPopoverOpen}
      padding={4}
      onClickOutside={onClickOutside}
      containerClassName={css.main}
      content={<div className={css.card}>{content}</div>}
    >
      {children}
    </Popover>
  );
}

export default FilterPopUp;
