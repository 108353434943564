import React, { useCallback, useEffect, useState, useMemo } from 'react';

import useQuote from '../../../hooks/useQuote';
import useNavigation from '../../../hooks/useNavigation';
import useSortedTags from '../../../hooks/useSortedTags';

import css from './QuoteDossier.module.css';
import { UrlParam, IdHash } from '../../../shared/dossier';

import NewLoadingState from '../NewLoadingState/NewLoadingState';
import DossierHeader from '../../../../components/DossierHeader/DossierHeader';
import DossierTitle from '../DossierTitle/DossierHeader';
import { BaseCard } from '../../Cards/BaseCard/BaseCard';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import EventPill from '../../EventPill/EventPill';
import AiBadge from '../../AiBadge/AiBadge';
import DossierNotes from '../../DossierNotes/DossierNotes';
import Score from '../../Score/Score';
import { calcImportanceScore } from '../../../shared/events';

function QuoteDossier({ size, quoteId, onSizeChange }) {
  const {
    notes,
    quote,
    loading,
    isSavingNote,
    isDeletingNote,
    isUpdatingNote,
    deleteNote,
    createNote,
    updateNote,
  } = useQuote(quoteId);
  const { params, navigate, location } = useNavigation();

  const onPillClicked = (entity) => {
    params.delete('dossierEventId');
    params.delete('viewQuote');
    params.set('dossierEntityId', entity.id);
    params.set('dossierType', 'entity');
    navigate(
      location.pathname,
      params,
      { back: 'Back to Quote' },
      IdHash.entity,
    );
  };

  const scrollNotes = useCallback((node) => {
    if (params.get('viewNotes') === 'true' && node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const showOriginalQuote =
    quote?.originalQuoteLanguage && quote?.originalQuoteLanguage !== 'English';

  const score = useMemo(
    () => calcImportanceScore(quote?.magnitude, quote?.sentiment),
    [quote],
  );

  const { industries, countries } = useSortedTags(quote || {});

  if (loading) return <NewLoadingState />;
  if (!quote) return null;

  return (
    <div className={css.main}>
      <div className={css.header}>
        <DossierHeader size={size} onSizeChange={onSizeChange} />
        <div
          style={{ marginTop: -5, zIndex: 5, position: 'relative' }}
          className={css.divider}
        />
      </div>
      <div className={css.content}>
        <DossierTitle>Quote</DossierTitle>
        <h2 className={css.headline}>
          {`"${quote.translatedQuote || quote.quote}"`}
        </h2>
        <BaseCard.InfoColumn title="Quote by">
          <EntityPill
            id={quote.speaker.id}
            image={quote.speaker.image}
            name={quote.speaker.name}
            shortName={quote.speaker.shortestName}
            tooltip={quote.speaker.tooltip}
            type={quote.speaker.type}
            isConnected={quote.speaker.isConnected}
            inSubscription={quote.speaker.inSubscription}
            onClick={() => onPillClicked(quote.speaker)}
            size="small"
          />
        </BaseCard.InfoColumn>

        <div className={css.item}>
          <div className={css.itemTitle}>Business Relevance</div>
          <div className={css.itemContent}>
            <Score score={score} hideText customStyles={{ marginTop: '4px' }} />
          </div>
        </div>

        <BaseCard.InfoColumn title="Event">
          <EventPill id={quote.event.id} title={quote.event.title} />
        </BaseCard.InfoColumn>

        <BaseCard.InfoColumn
          title={
            <div className={css.context}>
              Context <AiBadge />
            </div>
          }
        >
          {quote.context}
        </BaseCard.InfoColumn>

        {showOriginalQuote && (
          <BaseCard.InfoColumn title="Original">
            {quote.quote}
          </BaseCard.InfoColumn>
        )}

        <BaseCard.InfoColumn title="Industries">
          <BaseCard.BriefPillList briefs={industries} />
        </BaseCard.InfoColumn>

        <BaseCard.InfoColumn title="Countries">
          <BaseCard.BriefPillList briefs={countries} isGeography />
        </BaseCard.InfoColumn>

        {showOriginalQuote && (
          <BaseCard.InfoColumn title="Language">
            {quote.originalQuoteLanguage}
          </BaseCard.InfoColumn>
        )}
      </div>
      <div className={css.divider} ref={scrollNotes} />

      <DossierNotes
        notes={notes}
        saveNote={(note) => createNote(quoteId, note)}
        onDeleteNote={deleteNote}
        onEditNote={updateNote}
        isSavingNote={isSavingNote}
        isUpdatingNote={isUpdatingNote}
        isDeletingNote={isDeletingNote}
      />
    </div>
  );
}

export default function QuoteDossierWrapper({ size, onSizeChange }) {
  const [isDossierOpen, setIsDossierOpen] = useState(false);
  const { params } = useNavigation();

  useEffect(() => {
    const dossierQuoteId = params.get(UrlParam.quoteId);
    const dossierType = params.get('dossierType');

    if (dossierQuoteId && dossierType) {
      setIsDossierOpen(true);
    } else {
      setIsDossierOpen(false);
    }
  }, [params]);

  if (!isDossierOpen) return null;

  return (
    <QuoteDossier
      size={size}
      quoteId={params.get(UrlParam.quoteId)}
      onSizeChange={onSizeChange}
    />
  );
}
