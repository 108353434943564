import { gql } from '@apollo/client';

const query = gql`
  query getAppointmentById($appointmentId: ID!) {
    getAppointmentById(appointmentId: $appointmentId) {
      id
      title
      organization
      headline
      startDate
      magnitude
      sentiment
      entity {
        id
        name
        image
        type
        inSubscription
        isConnected
        shortestName
        tooltip
      }
      industries {
        id
        name
        industryRelevance
      }
      countries {
        id
        name
        locationRelevance
      }
      organizationEntity {
        id
        name
        image
        type
        inSubscription
        isConnected
        shortestName
        tooltip
      }
      createdAt
      updatedAt
      source
      notesCount
    }
  }
`;

export default query;
