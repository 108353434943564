import React from 'react';

import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import SavedSearchs from '../../../icons/SavedSearchs';
import Bookmark from '../../../icons/Bookmark';
import Connection from '../../../icons/Connection';
import Compliant from '../../../icons/Compliant';
import { useIdentity } from '../../../contexts/IdentityContext';
import AxisFullLogo from '../../../icons/AxisFullLogo';
import AxisLogo from '../../../icons/AxisLogo';

import css from './SideNav.module.css';
import useNavigation from '../../hooks/useNavigation';
import useSupportChat from '../../hooks/useSupportChat';
import Notifications from '../../../icons/NotificationBell';
import NotificationsOutline from '../../../icons/NotificationBellOutline';
import NotificationCount from './NotificationCount/NotificationCount';
import { ROUTES } from '../../shared/routes';
import NewExplore from '../../../icons/NewExplore';
import NotesOutline from '../../../icons/NotesOutline';
import NewFeed from '../../../icons/NewFeed';
import Support from '../../../icons/Support';
import { IdHash } from '../../shared/dossier';

function useLeftPanel() {
  const { replaceParams, location, params } = useNavigation();
  const paramName = 'leftPanel';
  const leftPanel = params.get(paramName);
  const isLeftPanel = Boolean(
    leftPanel && location.hash === `#${IdHash.leftPanel}`,
  );

  const refreshHash = () => replaceParams(params, IdHash.leftPanel);

  const toggleLeftPanel = (panel, toggle = true) => {
    const isSamePanel = panel === leftPanel;
    params.delete('isFilterOpen');

    if (isSamePanel && toggle) {
      params.delete(paramName, panel);
    } else {
      params.set(paramName, panel);
    }
    refreshHash();
  };

  const openLeftPanel = (panel) => toggleLeftPanel(panel, false);
  return {
    leftPanel,
    isLeftPanel,
    refreshHash,
    toggleLeftPanel,
    openLeftPanel,
  };
}

export function SideNav() {
  const { user } = useIdentity();
  const { leftPanel, toggleLeftPanel } = useLeftPanel();

  const chat = useSupportChat(window.Atlas?.chat);

  const { pathname } = window.location;
  const isExploreActive = pathname.includes('/search');
  const isFeedActive = pathname.includes('/feed');

  const isSavedSearchOpen = leftPanel === 'saved-searches';
  const isBookmarkOpen = leftPanel === 'bookmarks';
  const isNotesOpen = leftPanel === 'notes';
  const isConnectionsOpen = leftPanel === 'connections';
  const isCompliantOpen = leftPanel === 'compliance';
  const isNotificationOpen = leftPanel === 'notifications';

  return (
    <div className={css.container}>
      <div id="side-nav" className={css.main}>
        <Link to="/" className={css.link}>
          <AxisFullLogo className={css.logo} />
        </Link>

        <Link
          to={`${ROUTES.ADV_SEARCH}?isFilterOpen=true`}
          className={css.explore}
          data-active={isExploreActive}
        >
          <NewExplore />
          <h3 className={css.itemTitle}>Explore</h3>
        </Link>

        <Link
          to={ROUTES.FEED}
          className={css.explore}
          data-active={isFeedActive}
        >
          <NewFeed />
          <h3 className={css.itemTitle}>Feed</h3>
        </Link>

        <div className={css.divider} />

        <SideNavButton
          isDock
          title="Searches"
          icon={<SavedSearchs />}
          isActive={isSavedSearchOpen}
          data-cy="toggle-saved-searches"
          onClick={() => toggleLeftPanel('saved-searches')}
        />

        <SideNavButton
          isDock
          title="Bookmarks"
          icon={<Bookmark />}
          isActive={isBookmarkOpen}
          data-cy="toggle-bookmarks"
          onClick={() => toggleLeftPanel('bookmarks')}
        />

        <SideNavButton
          isDock
          title="Notes"
          icon={<NotesOutline />}
          isActive={isNotesOpen}
          data-cy="toggle-notes"
          onClick={() => toggleLeftPanel('notes')}
        />

        <SideNavButton
          isDock
          title="Contacts"
          icon={<Connection />}
          isActive={isConnectionsOpen}
          data-cy="toggle-connections"
          onClick={() => toggleLeftPanel('connections')}
        />

        <SideNavButton
          isDock
          title="Compliance"
          icon={<Compliant />}
          isActive={isCompliantOpen}
          data-cy="toggle-compliance"
          onClick={() => toggleLeftPanel('compliance')}
        />

        <div className={css.bottomIcons}>
          <SideNavButton
            isDock
            title="Notifications"
            icon={<Notifications />}
            isActive={isNotificationOpen}
            data-cy="toggle-notifications"
            onClick={() => toggleLeftPanel('notifications')}
          >
            <NotificationCount />
          </SideNavButton>

          {chat.isLoaded && (
            <SideNavButton
              isDock
              title="Chat with us"
              icon={<Support />}
              isActive={chat.isOpen}
              data-cy="toggle-support-chat"
              onClick={() => chat.toggle()}
            />
          )}

          <div className={css.halfDivider} />
          <Avatar user={user} />
        </div>
      </div>
    </div>
  );
}

function SideNavButton({
  routerLink = null,
  icon,
  title,
  isActive,
  onClick,
  isDock,
  children,
}) {
  const Wrapper = routerLink ? Link : 'div';

  return (
    <Wrapper
      to={routerLink}
      className={css.wrapper}
      data-active={isActive}
      data-dock={isDock}
      onClick={onClick}
    >
      <div className={css.sideButtonIcon}>{icon}</div>
      <h3>{title}</h3>
      {children}
    </Wrapper>
  );
}

function MobileButton({ title, children, isActive, onClick }) {
  return (
    <div
      type="button"
      className={css.mobileButton}
      data-active={Boolean(isActive)}
      onClick={onClick}
    >
      {children}
      {title}
    </div>
  );
}

function ProfileTab({ title, isActive, onClick }) {
  return (
    <div
      type="button"
      className={css.profileTab}
      data-active={Boolean(isActive)}
      onClick={onClick}
    >
      {title}
    </div>
  );
}

export function MobileProfileTabs() {
  const { leftPanel, isLeftPanel, openLeftPanel } = useLeftPanel();

  if (!isLeftPanel || leftPanel === 'notifications') return null;

  const buttons = [
    {
      title: 'Searches',
      isActive: leftPanel === 'saved-searches',
      onClick: () => openLeftPanel('saved-searches'),
    },
    {
      title: 'Bookmarks',
      isActive: leftPanel === 'bookmarks',
      onClick: () => openLeftPanel('bookmarks'),
    },
    {
      title: 'Notes',
      isActive: leftPanel === 'notes',
      onClick: () => openLeftPanel('notes'),
    },
    {
      title: 'Contacts',
      isActive: leftPanel === 'connections',
      onClick: () => openLeftPanel('connections'),
    },
    {
      title: 'Compliance',
      isActive: leftPanel === 'compliance',
      onClick: () => openLeftPanel('compliance'),
    },
  ];

  return (
    <ul id="profile-tabs" className={css.profileTabs}>
      {buttons.map(({ title, isActive, onClick }) => (
        <li key={title}>
          <ProfileTab title={title} isActive={isActive} onClick={onClick} />
        </li>
      ))}
    </ul>
  );
}

export function MobileNav() {
  const { user } = useIdentity();
  const { navigate, location } = useNavigation();
  const { leftPanel, refreshHash, openLeftPanel } = useLeftPanel();

  const isNotificationOpen = leftPanel === 'notifications';
  const isDossierOpen = location.hash.slice(1).startsWith(IdHash.dossier);

  // if (isDossierOpen) return null;

  const { pathname } = window.location;

  return (
    <nav id="mobile-nav" className={css.mobileNav}>
      <ul className={css.mobileButtons}>
        <li>
          <MobileButton
            title="Home"
            isActive={pathname === ROUTES.ROOT && !leftPanel}
            onClick={() => navigate(ROUTES.ROOT, '')}
          >
            <AxisLogo />
          </MobileButton>
        </li>
        <li>
          <MobileButton
            title="Notifications"
            isActive={isNotificationOpen}
            onClick={() => openLeftPanel('notifications')}
          >
            <div className={css.mobileBadge}>
              <NotificationsOutline />
              <NotificationCount />
            </div>
          </MobileButton>
        </li>
        <li>
          <MobileButton
            title="Profile"
            isActive={!isNotificationOpen && leftPanel}
            onClick={() =>
              // only open default tab if we're not on left panel or it's notifications
              !leftPanel || isNotificationOpen
                ? openLeftPanel('saved-searches')
                : refreshHash()
            }
          >
            <div className={css.avatar}>{user.name.charAt(0)}</div>
          </MobileButton>
        </li>
      </ul>
    </nav>
  );
}
