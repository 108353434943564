import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { uniqueId } from 'lodash';

import { useAxisData } from '../../../../contexts/AxisDataContext';
import GET_QUOTES from '../../../../queries/getQuotes';
import SmallQuoteCard from '../../../components/Cards/SmallQuoteCard/SmallQuoteCard';
import useNavigation from '../../../hooks/useNavigation';
import { ROUTES } from '../../../shared/routes';
import { List } from '../List/List';

function Quotes({ countryId, industryId, endDate, startDate, count, ...rest }) {
  const { navigate } = useNavigation();
  const { flatIndustries, topics } = useAxisData();

  const [quotes, setQuotes] = useState([]);
  const [variables, setVariables] = useState({
    filterCategories: {
      events: {
        eventFilters: ['quote'],
        limit: 10,
        nextToken: null,
      },
    },
    topics: [countryId],
    filterIndustries: [industryId],
    endDate: new Date(endDate),
    startDate: new Date(startDate),
  });

  const { loading } = useQuery(GET_QUOTES, {
    variables,
    onCompleted: (data) => {
      const { results } = data.searchEventsAndRegulations.events;
      setQuotes(results);
    },
  });

  useEffect(() => {
    setVariables((current) => {
      return {
        ...current,
        topics: [countryId],
        filterIndustries: [industryId],
        endDate: new Date(endDate),
        startDate: new Date(startDate),
      };
    });
  }, [industryId, countryId, startDate, endDate]);

  const handleSeeAll = () => {
    const industry = flatIndustries.find(({ id }) => id === industryId);
    const country = topics.find(({ id }) => id === countryId);

    const params = new URLSearchParams();
    const uuid = uniqueId('search-');
    params.set('queryId', uuid);
    params.set('isFilterOpen', true);
    sessionStorage.setItem(
      uuid,
      JSON.stringify({
        industries: [industry],
        countries: [country],
        dates: [
          new Date(startDate).toISOString(),
          new Date(endDate).toISOString(),
        ],
        searchTypes: 'event',
        eventTypes: ['quote'],
      }),
    );

    navigate(ROUTES.ADV_SEARCH, params);
  };

  return (
    <List
      loading={loading}
      title="Quotes"
      count={count}
      empty={quotes.length === 0}
      onClickSeeAll={handleSeeAll}
      {...rest}
    >
      {quotes.map((quote) => (
        <SmallQuoteCard key={quote.id} quote={quote} />
      ))}
    </List>
  );
}

export default Quotes;
