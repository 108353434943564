import React, { memo, useMemo } from 'react';

import { subDays } from 'date-fns';
import css from './TreeMapWrapper.module.css';
import TreeMap from './TreeMap/TreeMap';
import BetaBadge from '../BetaBadge/BetaBadge';
import useTreeMap from './useTreeMap';
import { buildTreeOptions } from '../../../utils';
import { useAxisData } from '../../../contexts/AxisDataContext';
import LoadingState from './LoadingState/LoadingState';
import useNavigation from '../../hooks/useNavigation';
import useLocalStorage from '../../hooks/useLocalStorage';
import IndustryFilter from '../Filters/IndustryFilter/IndustryFilter';
import CountryFilter from '../Filters/CountryFilter/CountryFilter';
import DateFilter from '../Filters/DateFilter/DateFilter';
import { ROUTES } from '../../shared/routes';

const now = new Date();

function TreeMapWrapper() {
  const [industries, setIndustries] = useLocalStorage('tmIndustries', []);
  const [countries, setCountries] = useLocalStorage('tmCountries', []);
  const [dates, setDates] = useLocalStorage('tmDates', [subDays(now, 1), now]);
  const { nodes, loading, applyFilters } = useTreeMap({
    industries,
    countries,
    dates,
  });
  const navigate = useNavigation();

  const { briefs } = useAxisData();

  const setSelectedIndustries = (ind) => {
    setIndustries(ind);
    applyFilters(ind, countries, dates);
  };

  const setSelectedCountries = (country) => {
    setCountries(country);
    applyFilters(industries, country, dates);
  };

  const setDateRange = (range) => {
    setDates(range);
    applyFilters(industries, countries, range);
  };

  const onNodeClick = (node) => {
    const params = new URLSearchParams();
    params.set('industryId', node.industry.id);
    params.set('countryId', node.country.id);
    params.set('startDate', new Date(dates[0]).getTime());
    params.set('endDate', new Date(dates[1]).getTime());
    navigate.navigate(ROUTES.SCATTER_PLOT, params);
  };

  const options = useMemo(() => {
    return buildTreeOptions(briefs);
  }, [briefs]);

  return (
    <div className={css.main}>
      <div className={css.header}>
        <div className={css.title}>
          Markets <BetaBadge />
        </div>

        <div className={css.filters}>
          <IndustryFilter
            options={options}
            customClass={css.industryFilter}
            applyFilters={setSelectedIndustries}
            activeIndustryFilters={industries}
            openAtBottom
          />

          <CountryFilter
            customClass={css.countryFilter}
            activeCountries={countries}
            setActiveCountries={setSelectedCountries}
            openAtBottom
          />

          <DateFilter
            customClass={css.dateFilter}
            dateInterval={dates}
            setDateInterval={setDateRange}
            openAtBottom
          />
        </div>
      </div>
      {loading && <LoadingState />}
      {!loading && <TreeMap nodes={nodes} onNodeClick={onNodeClick} />}
    </div>
  );
}

export default memo(TreeMapWrapper);
