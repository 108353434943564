import React, { useMemo } from 'react';

import useNavigation from '../../../hooks/useNavigation';
import { calcImportanceScore } from '../../../shared/events';

import css from './SmallAppointmentCard.module.css';

import { BaseCard } from '../BaseCard/BaseCard';
import Score from '../../Score/Score';
import EntityPill from '../../../../components/EntityPill/EntityPill';

/*
  Used in treemap
*/

function SmallAppointmentCard({ appointment }) {
  const { toggleAppointmentDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(appointment.magnitude, appointment.sentiment),
    [appointment],
  );

  return (
    <BaseCard.Root className={css.main}>
      <BaseCard.LeftColumn>
        <BaseCard.DateRow
          date={appointment.startDate}
          before={<Score score={score} />}
        />
        <BaseCard.Title
          onClick={() => toggleAppointmentDossier(appointment.id)}
        >
          {appointment.headline}
        </BaseCard.Title>
      </BaseCard.LeftColumn>

      <BaseCard.RightColumn className={css.right}>
        <BaseCard.InfoColumn title="Appointment" className={css.info}>
          <EntityPill
            id={appointment.entity.id}
            image={appointment.entity.image}
            name={appointment.entity.name}
            shortName={appointment.entity.shortestName}
            tooltip={appointment.entity.tooltip}
            type={appointment.entity.type}
            isConnected={appointment.entity.isConnected}
            inSubscription={appointment.entity.inSubscription}
            autoOpenDossier
            size="small"
          />
        </BaseCard.InfoColumn>
      </BaseCard.RightColumn>
    </BaseCard.Root>
  );
}

export default SmallAppointmentCard;
