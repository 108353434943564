import React, { useEffect, useRef } from 'react';

import css from './PopUpCard.module.css';
import Button from '../../../Buttom/Buttom';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';

function Root({ children }) {
  return <div className={css.root}>{children}</div>;
}

function Header({ title, count, inputValue, onInputChange, isPopupOpen }) {
  const inputRef = useRef();
  useOnKeyDown(inputRef, 'Escape', (event) => {
    onInputChange({ ...event, target: { value: '' } });
  });

  useEffect(() => {
    if (isPopupOpen) {
      inputRef.current.focus();
    }
  }, [isPopupOpen]);

  return (
    <div className={css.header}>
      <div className={css.headline}>
        <span className={css.title}>{title}</span>
        <span className={css.count}>{count}</span>
      </div>
      <input
        type="text"
        ref={inputRef}
        placeholder="Search"
        value={inputValue}
        onChange={onInputChange}
        className={css.input}
      />
    </div>
  );
}

function Body({ children, customClass }) {
  return <div className={`${css.body} ${customClass}`}>{children}</div>;
}

function Footer({ onCancel, onApply, customClass }) {
  return (
    <div className={`${css.footer} ${customClass}`}>
      <Button type="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" onClick={onApply}>
        Apply
      </Button>
    </div>
  );
}

function DatePickerHeader({ startDate, endDate }) {
  return (
    <div className={css.datePickerHeader}>
      <span className={css.timeline}>Date</span>
      {startDate && (
        <span className={css.date}>
          {startDate} - {endDate}
        </span>
      )}
    </div>
  );
}

function DateFooter({ onCancel, onApply, onClear, customClass }) {
  return (
    <div className={`${css.footerDates} ${customClass}`}>
      <Button type="ghost" onClick={onClear}>
        Clear
      </Button>
      <div className={css.dateActions}>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  );
}

export const PopUpCard = {
  Header,
  Root,
  Body,
  Footer,
  DateFooter,
  DatePickerHeader,
};
