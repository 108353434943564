import React, { memo } from 'react';

import css from './Sources.module.css';
import Badge from '../../../../../icons/Badge';
import Event from '../../../../../icons/Event';
import Location from '../../../../../icons/Location';
import Apartment from '../../../../../icons/Apartment';
import Regulation from '../../../../../icons/Regulation';
import LinkCard from '../../../../components/LinkCard/LinkCard';
import BriefPill from '../../../../../components/BriefPill/BriefPill';
import EntityPill from '../../../../../components/EntityPill/EntityPill';

import useNavigation from '../../../../hooks/useNavigation';

function Sources({ sources, isLoading }) {
  const { toggleRegulationDossier, toggleEventDossier, toggleEntityDossier } =
    useNavigation();

  const onPillClicked = (actor) => {
    toggleEntityDossier(actor.id);
  };

  const openRegulationDossier = (regulationId) => {
    if (!regulationId) return;

    toggleRegulationDossier(regulationId);
  };

  const openEventDossier = (eventId) => {
    if (!eventId) return;
    toggleEventDossier(eventId);
  };

  return (
    <div className={css.main} data-cy="semantic-search-sources">
      <div className={css.sourcesHeader}>
        <h2>Axis sources</h2>
      </div>

      <div className={css.firstContainer}>
        <GridItem
          title="Actors"
          icon={<Badge />}
          isLoading={isLoading}
          hasSource={sources?.actors.length}
        >
          <div className={css.tagList} data-cy="source-actors">
            {sources?.actors?.map((actor) => (
              <EntityPill
                size="small"
                key={actor.id}
                image={actor.image}
                type={actor.type}
                name={actor.name}
                onClick={() => onPillClicked(actor)}
                inSubscription={actor.inSubscription}
                isConnected={actor.isConnected}
              />
            ))}
          </div>
        </GridItem>

        <GridItem
          title="Industries"
          icon={<Apartment />}
          isLoading={isLoading}
          hasSource={sources?.industries.length}
        >
          <div className={css.tagList} data-cy="source-industries">
            {sources?.industries?.map((industry) => (
              <BriefPill key={industry} name={industry} />
            ))}
          </div>
        </GridItem>

        <GridItem
          title="Countries"
          icon={<Location />}
          isLoading={isLoading}
          hasSource={sources?.locations.length}
        >
          <div className={css.tagList} data-cy="source-locations">
            {sources?.locations?.map((location) => (
              <BriefPill key={location} name={location} isGeography="true" />
            ))}
          </div>
        </GridItem>
      </div>

      <GridItem
        title="Events"
        icon={<Event />}
        isLoading={isLoading}
        hasSource={sources?.events.length}
      >
        <div
          className={css.eventList}
          style={{ gap: 16 }}
          data-cy="source-events"
        >
          {sources?.events?.map((event) => (
            <LinkCard
              key={event.id}
              title={event.title}
              src={event.source}
              onClick={(e) => {
                e.stopPropagation();
                openEventDossier(event.id);
              }}
            />
          ))}
        </div>
      </GridItem>

      <GridItem
        listGap={16}
        title="Regulations"
        icon={<Regulation />}
        isLoading={isLoading}
        hasSource={sources?.regulations.length}
      >
        <div
          className={css.eventList}
          style={{ gap: 16 }}
          data-cy="source-regulations"
        >
          {sources?.regulations?.map((regulation) => (
            <LinkCard
              key={regulation.id}
              title={regulation.summarized_name || regulation.legal_name}
              src={regulation.source}
              onClick={(e) => {
                e.stopPropagation();
                openRegulationDossier(regulation.id);
              }}
            />
          ))}
        </div>
      </GridItem>
    </div>
  );
}

export default memo(Sources);

const GridItem = ({ hasSource, icon, title, children, isLoading }) => {
  if (!hasSource && !isLoading) return null;

  return (
    <div>
      <div className={css.itemHeader}>
        {icon} {title}
      </div>

      {isLoading && <div style={{ height: 30 }} className="skeleton-v2" />}

      {!isLoading && children}
    </div>
  );
};
