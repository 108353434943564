import React, { useEffect, useMemo, useRef, useState } from 'react';

import { buildCountryOptions, filterCountriesByNameAndAltNames } from './utils';
import css from './CountryFilter.module.css';
import { PopUpCard } from '../components/PopUpCard/PopUpCard';
import ListDivider from '../components/ListDivider/ListDivider';
import FilterPopUp from '../components/FilterPopUp/FilterPopUp';
import FilterButton from '../components/FilterButton/FilterButton';
import { useAxisData } from '../../../../contexts/AxisDataContext';
import CountryOption from '../components/CountryOption/CountryOption';
import SingleSelectionButton from '../components/SingleSelectionButton/SingleSelectionButton';

function CountryFilter({
  activeCountries,
  setActiveCountries,
  customClass,
  singleSelection = false,
  openAtBottom = false,
}) {
  const buttonRef = useRef();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const { subscribedCountries } = useAxisData();

  const onClose = () => {
    setIsPopupOpen(false);
    setQuery('');
  };

  const onCancel = () => {
    const activeOptionIds = new Set(activeCountries.map(({ id }) => id));

    setOptions((items) =>
      items.map((item) => {
        const isSelected = activeOptionIds.has(item.id);
        return item.selected !== isSelected
          ? { ...item, selected: isSelected }
          : item;
      }),
    );

    onClose();
  };

  const onApply = () => {
    const selectedOptions = options.filter(({ selected }) => selected);
    setActiveCountries(selectedOptions);
    setIsPopupOpen(false);
  };

  const onInputChange = (event) => {
    setQuery(event.target.value);
  };

  const updateOptions = (subCountries, countries) => {
    setOptions(buildCountryOptions(subCountries, countries));
  };

  const handleButtonClick = () => {
    updateOptions(subscribedCountries, activeCountries);
    setIsPopupOpen(true);
  };

  const onSelectAll = () => {
    if (singleSelection) return;

    setOptions((currentOptions) => {
      return currentOptions.map((item) => ({ ...item, selected: true }));
    });
  };

  const onDeselectAll = () => {
    setOptions((currentOptions) => {
      return currentOptions.map((item) => ({ ...item, selected: false }));
    });
  };

  const onOptionChanged = (option) => {
    setOptions((currentOptions) => {
      return currentOptions.map((item) => {
        if (singleSelection) {
          // If singleSelection is true, deselect all other items and select the clicked one
          return { ...item, selected: item.id === option.id };
        }

        // If singleSelection is false, toggle the selected state of the clicked item
        if (item.id === option.id) {
          return { ...item, selected: !item.selected };
        }

        return item;
      });
    });
  };

  useEffect(() => {
    if (options.length === 0 && subscribedCountries) {
      updateOptions(subscribedCountries, activeCountries);
    }
  }, [subscribedCountries, activeCountries, isPopupOpen]);

  const count = useMemo(() => {
    return activeCountries.length;
  }, [activeCountries]);

  const filteredOptions = useMemo(() => {
    return filterCountriesByNameAndAltNames(options, query);
  }, [query, options]);

  const selectedOptions = useMemo(() => {
    if (activeCountries.length === 0 || options.length === 0) return [];

    const activeOptionIds = new Set(activeCountries.map(({ id }) => id));
    return options.filter((opt) => activeOptionIds.has(opt.id));
  }, [activeCountries, options]);

  return (
    <div
      className={`${css.main} ${customClass}`}
      data-cy="country-filter"
      ref={buttonRef}
    >
      <FilterPopUp
        isPopoverOpen={isPopupOpen}
        onClickOutside={onClose}
        openAtBottom={openAtBottom}
        content={
          <PopUpCard.Root>
            <PopUpCard.Header
              title="Countries"
              count={singleSelection ? '' : count}
              inputValue={query}
              isPopupOpen={isPopupOpen}
              onInputChange={onInputChange}
            />
            <PopUpCard.Body>
              <div className={css.list}>
                {selectedOptions.length > 0 && (
                  <>
                    <ListDivider
                      title={
                        singleSelection
                          ? 'SELECTED COUNTRY'
                          : 'SELECTED COUNTRIES'
                      }
                      buttonLabel={singleSelection ? '' : 'Deselect All'}
                      onClick={onDeselectAll}
                    />
                    {selectedOptions.map((option) => (
                      <CountryOption
                        key={option.id}
                        option={option}
                        onChange={onOptionChanged}
                      />
                    ))}
                  </>
                )}

                <ListDivider
                  title="ALL COUNTRIES"
                  buttonLabel={singleSelection ? '' : 'Select All'}
                  onClick={onSelectAll}
                />
                {filteredOptions.map((option) => (
                  <CountryOption
                    key={option.id}
                    option={option}
                    onChange={onOptionChanged}
                  />
                ))}
              </div>
            </PopUpCard.Body>
            <PopUpCard.Footer onApply={onApply} onCancel={onCancel} />
          </PopUpCard.Root>
        }
      >
        {singleSelection ? (
          <SingleSelectionButton
            aria-pressed={isPopupOpen}
            name={activeCountries?.[0]?.name || 'Select Country'}
            count={count}
            onClick={handleButtonClick}
          />
        ) : (
          <FilterButton
            aria-pressed={isPopupOpen}
            name="Countries"
            count={count}
            onClick={handleButtonClick}
          />
        )}
      </FilterPopUp>
    </div>
  );
}

export default CountryFilter;
