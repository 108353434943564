import React, { useEffect, useReducer, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';
import css from './EntityFilter.module.css';
import FilterPopUp from '../components/FilterPopUp/FilterPopUp';
import { PopUpCard } from '../components/PopUpCard/PopUpCard';
import FilterButton from '../components/FilterButton/FilterButton';
import { ACTIONS, entityFilterInitialState, reducer } from './reducer';
import PopupContent from './PopupContent/PopupContent';
import SEARCH_ACTORS from '../../../../queries/searchEntities';
import useDebounce from '../../../../hooks/useDebounce';

function EntityFilter({
  activeEntities,
  setActiveEntities,
  openAtBottom = false,
}) {
  const buttonRef = useRef();
  const [state, dispatch] = useReducer(reducer, entityFilterInitialState);
  const [fetchActors, { loading }] = useLazyQuery(SEARCH_ACTORS, {
    onCompleted: (data) => {
      const results = data.searchEntities.entities || [];
      dispatch({ type: ACTIONS.UPDATE_SEARCH_RESULTS, payload: results });
    },
  });

  const debouncedText = useDebounce(state.query, 300);

  const onClose = () => {
    dispatch({ type: ACTIONS.CLOSE_POPUP });
  };

  const handleButtonClick = () => {
    dispatch({ type: ACTIONS.OPEN_POPUP });
  };

  const onApply = () => {
    setActiveEntities(state.selectedOptions.filter(({ selected }) => selected));
    dispatch({ type: ACTIONS.CLOSE_POPUP });
  };

  const onCancel = () => {
    dispatch({ type: ACTIONS.CLOSE_POPUP });
  };

  const onInputChange = (event) => {
    dispatch({ type: ACTIONS.UPDATE_QUERY, payload: event.target.value });
  };

  const handleOnOptionChange = (option) => {
    const isSelecting = !option.selected;

    if (isSelecting) {
      dispatch({ type: ACTIONS.SELECT_OPTION, payload: option });
      return;
    }
    dispatch({ type: ACTIONS.UNSELECT_OPTION, payload: option });
  };

  const findActors = (text) => {
    if (text === '') {
      dispatch({ type: ACTIONS.UPDATE_SEARCH_RESULTS, payload: [] });
      return;
    }

    fetchActors({
      variables: {
        name: text,
        limit: 20,
      },
    });
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.SYNC_SELECTED_OPTIONS, payload: activeEntities });
  }, [activeEntities]);

  useEffect(() => {
    findActors(debouncedText);
  }, [debouncedText]);

  return (
    <div className={css.main} data-cy="entity-filter" ref={buttonRef}>
      <FilterPopUp
        isPopoverOpen={state.isPopupOpen}
        onClickOutside={onClose}
        openAtBottom={openAtBottom}
        content={
          <PopUpCard.Root>
            <PopUpCard.Header
              title="Actors"
              isPopupOpen={state.isPopupOpen}
              count={activeEntities.length}
              inputValue={state.query}
              onInputChange={onInputChange}
            />
            <PopUpCard.Body>
              <div className={css.list} onClick={(e) => e.stopPropagation()}>
                <PopupContent
                  query={debouncedText}
                  isLoading={loading}
                  searchResults={state.searchResults}
                  selectedOptions={state.selectedOptions}
                  onChange={handleOnOptionChange}
                  deselectAll={() => dispatch({ type: ACTIONS.DESELECT_ALL })}
                />
              </div>
            </PopUpCard.Body>
            <PopUpCard.Footer onApply={onApply} onCancel={onCancel} />
          </PopUpCard.Root>
        }
      >
        <FilterButton
          name="Actors"
          count={activeEntities.length}
          onClick={handleButtonClick}
        />
      </FilterPopUp>
    </div>
  );
}

export default EntityFilter;
