import React from "react";

/** Just the X symbol */
const Logo = ({ className }) => (
  <svg
    className={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.84027 6.55955e-07L0.166626 0.673645L17.493 18L18.1666 17.3264L0.84027 6.55955e-07Z"
      fill="url(#paint0_linear_8589_785)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.36715 16.7633L0.503418 17.6271C1.43361 18.5573 6.0078 15.4909 10.7203 10.7784C15.4329 6.06581 18.4993 1.49163 17.5691 0.561432L16.7053 1.42516C16.6921 1.46733 16.6765 1.51274 16.6588 1.562C16.4668 2.09003 16.0715 2.82252 15.4694 3.72108C14.2737 5.50449 12.3729 7.77856 10.0467 10.1047C7.72055 12.4309 5.44648 14.3317 3.66307 15.5274C2.7645 16.1295 2.03202 16.5247 1.50398 16.7169L1.42513 16.7444L1.36715 16.7633Z"
      fill="currentColor"
    />
    <path
      d="M17.5691 0.561283C16.6389 -0.368942 12.0647 2.69744 7.35215 7.40998C2.63961 12.1225 -0.426773 16.6967 0.503452 17.6269L1.36718 16.7632C1.38042 16.721 1.39598 16.6756 1.41367 16.6263C1.60576 16.0983 2.001 15.3659 2.60309 14.4673C3.79879 12.6839 5.69964 10.4098 8.02579 8.08362C10.352 5.75747 12.626 3.85662 14.4095 2.66089C15.308 2.05883 16.0405 1.66359 16.5685 1.4715C16.6178 1.45385 16.6632 1.43825 16.7053 1.42501L17.5691 0.561283Z"
      fill="url(#paint1_linear_8589_785)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8589_785"
        x1="0.503448"
        y1="0.336823"
        x2="17.8298"
        y2="17.6632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" stopOpacity="0" />
        <stop offset="0.223958" stopColor="currentColor" />
        <stop offset="0.78125" stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8589_785"
        x1="9.34039"
        y1="5.42175"
        x2="5.19825"
        y2="9.56392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" />
        <stop offset="0.307292" stopColor="currentColor" stopOpacity="0" />
        <stop offset="0.671875" stopColor="currentColor" stopOpacity="0" />
        <stop offset="1" stopColor="currentColor" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo
