import * as React from 'react';

function ChevronRight(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.267 12l-.934-.933L8.4 8 5.333 4.933 6.267 4l4 4-4 4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ChevronRight;
