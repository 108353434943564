import * as React from 'react';

function NotificationBellOutline(props) {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.832031 14.8332V13.1665H2.4987V7.33317C2.4987 6.18039 2.84592 5.15609 3.54036 4.26025C4.23481 3.36442 5.13759 2.77761 6.2487 2.49984V1.9165C6.2487 1.56928 6.37023 1.27414 6.61328 1.03109C6.85634 0.788032 7.15148 0.666504 7.4987 0.666504C7.84592 0.666504 8.14106 0.788032 8.38412 1.03109C8.62717 1.27414 8.7487 1.56928 8.7487 1.9165V2.49984C9.85981 2.77761 10.7626 3.36442 11.457 4.26025C12.1515 5.15609 12.4987 6.18039 12.4987 7.33317V13.1665H14.1654V14.8332H0.832031ZM7.4987 17.3332C7.04036 17.3332 6.648 17.17 6.32161 16.8436C5.99523 16.5172 5.83203 16.1248 5.83203 15.6665H9.16537C9.16537 16.1248 9.00217 16.5172 8.67578 16.8436C8.34939 17.17 7.95703 17.3332 7.4987 17.3332ZM4.16536 13.1665H10.832V7.33317C10.832 6.4165 10.5056 5.63178 9.85287 4.979C9.20009 4.32623 8.41536 3.99984 7.4987 3.99984C6.58203 3.99984 5.79731 4.32623 5.14453 4.979C4.49175 5.63178 4.16536 6.4165 4.16536 7.33317V13.1665Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default NotificationBellOutline;
