import React, { useMemo } from 'react';

import { differenceInDays } from 'date-fns';
import { useQuery } from '@apollo/client';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import {
  XCustomTick,
  YCustomTick,
  getDomain,
  renderCustomizedShape,
  splitDateDifference,
  yTicks,
} from './utils';
import css from './ScatterGraph.module.css';
import GET_GRAPH_DATA from '../../../../queries/getTreeMapDetails';

function ScatterGraph({
  industryId,
  countryId,
  endDate,
  startDate,
  setSelectedNode,
  selectedNodeId,
  onClickOutside,
}) {
  const { loading, data } = useQuery(GET_GRAPH_DATA, {
    variables: {
      industryId,
      countryId,
      endDate: new Date(endDate),
      startDate: new Date(startDate),
    },
  });

  const [graphData, largestEventsCount] = useMemo(() => {
    if (!data) return [null, null];

    return [
      data.getTreeMapDetails.data.map((item) => ({
        ...item,
        x: item.timestamp,
        y: item.score,
      })),
      data.getTreeMapDetails.largestEventsCount,
    ];
  }, [data]);

  const daysInterval = differenceInDays(endDate, startDate);

  if (loading) {
    return <div className={`skeleton-v2 ${css.loading}`} />;
  }

  return (
    <ResponsiveContainer className={css.main}>
      <ScatterChart margin={{ top: 10 }} onClick={onClickOutside}>
        <CartesianGrid
          strokeDasharray="3 3"
          stroke="#ccc"
          horizontal
          vertical={false}
        />
        <XAxis
          type="number"
          dataKey="x"
          ticks={splitDateDifference(startDate, endDate, daysInterval)}
          domain={getDomain(startDate, endDate)}
          name="Date"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={(props) => (
            <XCustomTick {...props} daysInterval={daysInterval} />
          )}
        />
        <YAxis
          dataKey="y"
          name="Value"
          orientation="right"
          ticks={yTicks}
          domain={[-10, 10]}
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          attributeName="timestamp"
          tickSize={5}
          interval={1}
          tick={<YCustomTick />}
        />

        <Scatter
          name="Events"
          data={graphData}
          shape={(props) =>
            renderCustomizedShape({
              ...props,
              largestEventsCount,
              onNodeClick: setSelectedNode,
              selectedNodeId,
            })
          }
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default ScatterGraph;
