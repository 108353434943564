import React, { useMemo } from 'react';

import useNavigation from '../../../hooks/useNavigation';
import { calcImportanceScore } from '../../../shared/events';

import css from './SmallQuoteCard.module.css';
import { BaseCard } from '../BaseCard/BaseCard';
import Score from '../../Score/Score';
import EntityPill from '../../../../components/EntityPill/EntityPill';

/*
  Used in treemap
*/

function SmallQuoteCard({ quote }) {
  const { toggleQuoteDossier } = useNavigation();

  const score = useMemo(
    () => calcImportanceScore(quote.magnitude, quote.sentiment),
    [quote],
  );

  const title = quote.translatedQuote || quote.quote;

  return (
    <BaseCard.Root className={css.main}>
      <BaseCard.LeftColumn>
        <BaseCard.DateRow date={quote.date} before={<Score score={score} />} />
        <BaseCard.Title onClick={() => toggleQuoteDossier(quote.id)}>
          {title}
        </BaseCard.Title>
      </BaseCard.LeftColumn>

      <BaseCard.RightColumn className={css.right}>
        <BaseCard.InfoColumn title="Quote by" className={css.info}>
          <EntityPill
            id={quote.speaker.id}
            image={quote.speaker.image}
            name={quote.speaker.name}
            shortName={quote.speaker.shortestName}
            tooltip={quote.speaker.tooltip}
            type={quote.speaker.type}
            isConnected={quote.speaker.isConnected}
            inSubscription={quote.speaker.inSubscription}
            autoOpenDossier
            size="small"
          />
        </BaseCard.InfoColumn>
      </BaseCard.RightColumn>
    </BaseCard.Root>
  );
}

export default SmallQuoteCard;
