import React from 'react';
import Helper from '../../../../icons/Helper';
import css from './Preferences.module.css';

function Preferences({ onClick }) {
  return (
    <div className={css.main}>
      <Helper className={css.icon} />
      <span className={css.label}>
        Based on your background, interests, countries, and industries.
      </span>
      <button className={css.button} type="button" onClick={onClick}>
        Change Preferences
      </button>
    </div>
  );
}

export default Preferences;
