import { useMemo } from 'react';

// backend shouldn't return anything lower than "significant"
const order = ['CORE', 'SIGNIFICANT', 'PERIPHERAL', null];

function sortByKey(items, key) {
  if (!items) return [];
  return [...items].sort((a, b) => {
    const indexA = order.indexOf(a[key]);
    const indexB = order.indexOf(b[key]);
    return indexA - indexB;
  });
}

const useSortedTags = (event) => {
  const { industries, countries } = event || {};

  const sortedIndustries = useMemo(
    () => sortByKey(industries, 'industryRelevance'),
    [industries],
  );

  const sortedCountries = useMemo(
    () => sortByKey(countries, 'locationRelevance'),
    [countries],
  );

  return { industries: sortedIndustries, countries: sortedCountries };
};

export default useSortedTags;
