import React, { useEffect, useState, useMemo, useRef } from 'react';

import css from './Tags.module.css';
import BriefPill from '../../../../../../components/BriefPill/BriefPill';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';

const putPrimaryTopicOnTop = (items, primaryTopicId) => {
  if (!primaryTopicId) return items;

  const index = items.findIndex((item) => item.id === primaryTopicId);
  if (index === -1) return items; // Item not found, return original array

  const arrayCopy = [...items];
  // Remove the item at the found index and store it in 'item'
  const [item] = arrayCopy.splice(index, 1);

  // Add the item to the beginning of the array
  arrayCopy.unshift(item);

  return arrayCopy;
};

/** Hook to fit a bunch of items into a fixed height container
 * uses repeated renders to recalculate the item counts
 * provides a boolean when list can be visible
 */
function useTagsContainer(items, containerRef, maxHeight) {
  const [ready, setReady] = useState(false);
  const [tags, setTags] = useState({ main: items, remaining: [] });

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need re-renders
  useEffect(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect?.height > maxHeight) {
      setTags((t) => {
        const { main } = t;
        const extra = main.pop();
        const remaining = t.remaining.concat(extra);
        return { main, remaining };
      });
    } else {
      setReady(true);
    }
  }, [tags]);

  return [tags, ready];
}

export function CountriesAndIndustries({
  countries,
  industries,
  primaryTopicId,
}) {
  const maxHeight = 20 + 6 + 20; // row+gap+row
  const containerRef = useRef(null);

  const allTags = useMemo(() => {
    const countryTags = putPrimaryTopicOnTop(countries, primaryTopicId);
    return countryTags.concat(industries);
  }, [countries, industries, primaryTopicId]);

  const [tags, ready] = useTagsContainer(allTags, containerRef, maxHeight);
  if (!allTags.length) return null;

  return (
    <div ref={containerRef} className={css.tags} aria-busy={!ready}>
      {tags.main.map(({ id, name }) => (
        <BriefPill className={css.pill} key={id} name={name} />
      ))}
      {tags.remaining.length > 0 && (
        <Tooltip
          text={
            <div className={css.tooltip}>
              {tags.remaining.map(({ name }) => (
                <React.Fragment key={name}>
                  {name} <br />
                </React.Fragment>
              ))}
            </div>
          }
        >
          <BriefPill className={css.pill} name={`+${tags.remaining.length}`} />
        </Tooltip>
      )}
    </div>
  );
}
