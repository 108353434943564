import React from 'react';

import css from './LeftPanel.module.css';
import useNavigation from '../../hooks/useNavigation';
import SavedSearches from './SavedSearches/SavedSearches';
import CompliantRegulations from './CompliantRegulations/CompliantRegulations';
import Connections from './Connections/Connections';
import Bookmarks from './Bookmarks/Bookmarks';
import Notes from './Notes/Notes';
import Notifications from './Notifications/Notifications';
import AbstractErrorBundary from '../AbstractErrorBundary/AbstractErrorBundary';
import ErrorState from './ErrorState/ErrorState';
import { IdHash } from '../../shared/dossier';

const panelComponentMap = {
  'saved-searches': <SavedSearches />,
  compliance: <CompliantRegulations />,
  connections: <Connections />,
  bookmarks: <Bookmarks />,
  notes: <Notes />,
  notifications: <Notifications />,
};

function LeftPanel() {
  const { params, location } = useNavigation();
  const panel = params.get('leftPanel');
  const isActive = location.hash.slice(1) === IdHash.leftPanel;

  const Component = panelComponentMap[panel] ?? null;

  return (
    <div
      id={IdHash.leftPanel}
      className={css.main}
      data-open={Boolean(panel)}
      data-active={isActive}
      data-panel={panel}
    >
      <AbstractErrorBundary fallback={<ErrorState />}>
        {Component}
      </AbstractErrorBundary>
    </div>
  );
}

export default LeftPanel;
