/* eslint-disable */
import React from 'react';
import { sendEvent } from '../../../../contexts/AnalyticsTrackingContext';
import { useRouteInformation } from '../../../../contexts/RouteInformationContext';
import css from './ResultCards.module.css';
import { getSearchResultAction } from '../../../../lib/search';
import EntityPill from '../../../../components/EntityPill/EntityPill';
import ResultQuoteCard from './ResultQuoteCard/ResultQuoteCard';
import AppointmentCard from '../../../../v2/components/Cards/AppointmentCard/AppointmentCard';
import RegulationCard from '../../../../v2/components/Cards/RegulationCard/RegulationCard';
import EventCard from '../../../../v2/components/Cards/EventCard/EventCard';

const ResultFacetRegulation = ({ facet }) => {
  return <RegulationCard regulation={facet} />;
};

export const ResultEntity = ({ entity = {} }) => {
  const router = useRouteInformation();

  const handleEntityClick = () => {
    sendEvent('advanced_search_entity_click', {
      description: 'Click on a result from the search page',
      entityId: entity.id,
      entityName: entity.name,
    });

    getSearchResultAction({ entity, router });
  };

  return (
    <div
      data-cy="search-results-entity"
      className={css.result}
      onClick={handleEntityClick}
    >
      <div data-cy="search-results-entity-data" className={css.resultInfo}>
        <div className={css.resultTitleContainer}>
          <EntityPill
            className={css.resultPill}
            name={entity.name}
            shortName={entity.shortestName}
            image={entity.image}
            type={entity.type}
            inSubscription={entity.inSubscription}
            isConnected={entity.isConnected}
            size="medium"
          />
        </div>
        {/* {entity.primaryRoleTitle && (
          <p
            data-cy="search-results-entity-description"
            className={css.entityDescription}
          >
            {entity.primaryRoleTitle}
          </p>
        )} */}
      </div>
    </div>
  );
};

const RenderByTypeName = ({ facet }) => {
  if (facet.__typename === 'SearchEvents') {
    return <EventCard event={facet} />;
  }
  if (facet.__typename === 'SearchQuote') {
    return <ResultQuoteCard facet={facet} />;
  }
  if (facet.__typename === 'SearchAppointment') {
    return <AppointmentCard appointment={facet} />;
  }

  return null;
};

export const ResultFacet = ({ facet = {}, type }) => {
  if (type === 'regulations') {
    return <ResultFacetRegulation facet={facet} />;
  }

  return <RenderByTypeName facet={facet} />;
};
