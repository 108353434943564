import React from 'react';
import css from './ToggleSidenav.module.css';
import FilterIcon from '../../../../icons/FilterIcon/FilterIcon';

function ToggleSideNav({ filterStatus, onClick }) {
  return (
    <button
      data-status={filterStatus}
      type="button"
      onClick={onClick}
      data-cy="feed-open-filters"
      className={css.toggleButton}
    >
      <FilterIcon />
    </button>
  );
}

export default ToggleSideNav;
