import React from 'react';
import {
  addDays,
  addHours,
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  roundToNearestMinutes,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { colorRangeMap } from '../../../components/TreeMap/TreeMap/utils';

import css from './ScatterGraph.module.css';

export const data = [
  {
    y: 3,
    eventIds: ['45b594fc-2ab9-48f9-bcf1-9fddaddb58db'],
    eventsCount: 1,
    x: new Date('2024-09-17T12:15:23.000Z').getTime(),
  },
  {
    y: 3,
    eventIds: ['966588ec-adf5-4c21-b8c2-5c1b06e6eff9'],
    eventsCount: 1,
    x: new Date('2024-09-21T06:15:23.000Z').getTime(),
  },
  {
    y: 2,
    eventIds: ['70fed7b2-a444-471b-a31c-e00c6b48c2c7'],
    eventsCount: 1,
    x: new Date('2024-09-22T12:15:23.000Z').getTime(),
  },
  {
    y: 0,
    eventIds: ['dab1a136-71c6-4616-be22-dc7a375731ca'],
    eventsCount: 1,
    x: new Date('2024-09-26T06:15:23.000Z').getTime(),
  },
  {
    y: 3,
    eventIds: ['fc03dbec-7e4c-480f-ab6b-495b1e408823'],
    eventsCount: 1,
    x: new Date('2024-09-26T06:15:23.000Z').getTime(),
  },
  {
    y: 2,
    eventIds: ['9bea3da8-4b67-4989-a187-46345dbea34c'],
    eventsCount: 1,
    x: new Date('2024-09-27T12:15:23.000Z').getTime(),
  },
  {
    y: -2,
    eventIds: ['270db17b-1c14-4e6d-b556-78cb84b1f673'],
    eventsCount: 1,
    x: new Date('2024-09-27T12:15:23.000Z').getTime(),
  },
  {
    y: -2,
    eventIds: [
      '7ca499b5-c554-40eb-8a80-189acab391d6',
      'c40e07f6-353a-4c09-bd3c-83850c46af48',
    ],
    eventsCount: 2,
    x: new Date('2024-10-01T06:15:23.000Z').getTime(),
  },
  {
    y: 0,
    eventIds: ['0178a701-15fa-4fed-bc6f-cabcedef988f'],
    eventsCount: 1,
    x: new Date('2024-10-03T18:15:23.000Z').getTime(),
  },
  {
    y: 2,
    eventIds: [
      '9f7edb78-737a-4681-858a-f226383030e1',
      'ee351514-7f82-4ba7-b0a4-250c03234108',
    ],
    eventsCount: 2,
    x: new Date('2024-10-03T18:15:23.000Z').getTime(),
  },
  {
    y: 0,
    eventIds: ['cd973b1c-7598-418f-a6c4-271f894da835'],
    eventsCount: 1,
    x: new Date('2024-10-07T12:15:23.000Z').getTime(),
  },
  {
    y: -2,
    eventIds: ['c52db185-43dc-49ff-94a9-c24a44f42d3c'],
    eventsCount: 1,
    x: new Date('2024-10-08T18:15:23.000Z').getTime(),
  },
  {
    y: -2,
    eventIds: ['ac1dc218-e7bb-4118-af1a-ebb65e5cff37'],
    eventsCount: 1,
    x: new Date('2024-10-11T06:15:23.000Z').getTime(),
  },
  {
    y: 2,
    eventIds: [
      '2d939926-bace-4963-a943-6d21d451a4b5',
      'f5b3cfa2-e4fb-4355-a1ba-57acf1373729',
    ],
    eventsCount: 2,
    x: new Date('2024-10-13T18:15:23.000Z').getTime(),
  },
  {
    y: 3,
    eventIds: [
      '234a35ea-a943-4798-9c76-5ce2ddf46413',
      '459196c7-9fab-40e5-84c1-a8340d0beb39',
    ],
    eventsCount: 2,
    x: new Date('2024-10-15T00:15:23.000Z').getTime(),
  },
  {
    y: 4,
    eventIds: ['79226616-28c5-4aaa-ad6c-23100752f0ff'],
    eventsCount: 1,
    x: new Date('2024-10-15T00:15:23.000Z').getTime(),
  },
  {
    y: 2,
    eventIds: [
      '0030fe7b-0e96-4e0b-b65e-5fabb00a4bfe',
      '1fc000bc-d1f1-43ea-85c9-1aa45d220e62',
      '349037f2-9583-438d-8244-b34b0c9ca56f',
      'a9b7aa4d-a4c0-4a73-acf2-c9a40ce872c6',
      'b01b8ad4-9a65-4300-b5ae-7f5078a8476f',
    ],
    eventsCount: 5,
    x: new Date('2024-10-16T06:15:23.000Z').getTime(),
  },
  {
    y: -3,
    eventIds: ['e8ae10b6-1d73-453d-8cfd-f3762be229e2'],
    eventsCount: 1,
    x: new Date('2024-10-16T06:15:23.000Z').getTime(),
  },
];

export const splitDateDifference = (startDate, endDate, daysInterval) => {
  let start = roundToNearestMinutes(new Date(startDate), { nearestTo: 30 });
  const end = roundToNearestMinutes(new Date(endDate), { nearestTo: 30 });

  if (daysInterval <= 1) {
    return Array.from({ length: 12 }, (_, i) =>
      addHours(start, (i + 1) * 2).getTime(),
    );
  }

  if (daysInterval <= 7) {
    start = startOfDay(start);
    return Array.from({ length: daysInterval + 1 }, (_, i) =>
      addDays(start, i).getTime(),
    );
  }

  if (daysInterval <= 94) {
    start = startOfDay(start);
    start = addDays(start, -1);
    return Array.from({ length: daysInterval + 1 }, (_, i) =>
      addDays(start, i).getTime(),
    );
  }

  start = startOfMonth(start);
  start = addMonths(start, -1);
  const length = differenceInMonths(endOfMonth(end), start) + 2;
  return Array.from({ length }, (_, i) => addMonths(start, i).getTime());
};

export const getDomain = (startDate, endDate) => {
  return [new Date(startDate).getTime(), new Date(endDate).getTime()];
};

const formatXAxis = (tickItem, daysInterval) => {
  switch (true) {
    case daysInterval <= 1:
      return format(new Date(tickItem), 'hh:mm a');
    case daysInterval <= 94:
      return format(new Date(tickItem), 'MMM dd');
    default:
      return format(new Date(tickItem), 'MMM');
  }
};

export const YCustomTick = ({ x, y, payload }) => {
  return (
    <text x={x} y={y} fill="#929AA3" fontSize={12} textAnchor="middle">
      {payload.value}
    </text>
  );
};

export const XCustomTick = ({ x, y, payload, daysInterval }) => {
  return (
    <text x={x} y={y + 10} fill="#929AA3" fontSize={12} textAnchor="middle">
      {formatXAxis(payload.value, daysInterval)}
    </text>
  );
};

export const yTicks = Array.from({ length: 21 }, (_, i) => -10 + i);

// Scale function to adjust the size based on event count
const scaleBubbleSize = (eventCount, largestEventsCount) => {
  const minSize = 5; // Minimum bubble size
  const maxSize = 20; // Maximum bubble size

  // Normalize the eventCount based on the largestEventsCount
  return minSize + (eventCount / largestEventsCount) * (maxSize - minSize);
};

export const renderCustomizedShape = (props) => {
  const {
    cx,
    cy,
    node,
    payload,
    largestEventsCount,
    onNodeClick,
    selectedNodeId,
  } = props;

  // Calculate the bubble size based on the number of events
  const bubbleSize = scaleBubbleSize(payload.eventsCount, largestEventsCount);

  let opacity = 0.85;

  if (selectedNodeId) {
    opacity = payload.id === selectedNodeId ? 1 : 0.3;
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      className={css.node}
      opacity={opacity}
      stroke="white"
      strokeWidth={selectedNodeId ? 0 : 2}
      r={bubbleSize}
      onClick={(e) => {
        e.stopPropagation();
        onNodeClick(payload);
      }}
      fill={colorRangeMap[node.y]}
    />
  );
};
