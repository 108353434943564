import React, { useState, useEffect } from 'react';
import { useApolloClient } from '../hooks/useApolloClient';

import DossierHeader from '../components/DossierHeader/DossierHeader';
import relationshipDossierEvents from '../queries/relationshipDossierEvents';
import relationshipDossierHeader from '../queries/relationshipDossierEntities';
import relationshipDossierRoles from '../queries/relationshipDossierRoles';
import relationshipDossierIndustries from '../queries/relationshipDossierIndustries';
import relationshipDossierLocations from '../queries/relationshipDossierLocations';
import relationshipDossierLinks from '../queries/relationshipDossierLinks';
import Dossier from './Dossier';
import { useAxisData } from '../contexts/AxisDataContext';
import ConnectionCard from '../components/ConnectionCard/ConnectionCard';
import { useIdentity } from '../contexts/IdentityContext';
import { useRouteInformation } from '../contexts/RouteInformationContext';

const fetchDossierRelationshipHeader = async ({ client, source, target }) => {
  return client
    .query({
      query: relationshipDossierHeader,
      variables: { sourceId: source, targetId: target },
    })
    .then(({ data }) => data.getRelationshipDossierHeader);
};

const fetchRelationshipEvents = async ({
  client,
  source,
  target,
  page = 1,
  limit = 100,
}) => {
  return client
    .query({
      query: relationshipDossierEvents,
      variables: { sourceId: source, targetId: target, page, limit },
    })
    .then(({ data }) => data.getRelationshipDossierSharedEvents);
};

const fetchSharedRoles = async ({
  client,
  source,
  target,
  page = 1,
  limit = 100,
}) => {
  return client
    .query({
      query: relationshipDossierRoles,
      variables: { sourceId: source, targetId: target, page, limit },
    })
    .then(({ data }) => data.getRelationshipDossierSharedRoles);
};

const fetchSharedLocations = async ({
  client,
  source,
  target,
  page = 1,
  limit = 100,
}) => {
  return client
    .query({
      query: relationshipDossierLocations,
      variables: { sourceId: source, targetId: target, page, limit },
    })
    .then(({ data }) => data.getRelationshipDossierSharedLocations);
};

const fetchSharedIndustries = async ({
  client,
  source,
  target,
  page = 1,
  limit = 100,
}) => {
  return client
    .query({
      query: relationshipDossierIndustries,
      variables: { sourceId: source, targetId: target, page, limit },
    })
    .then(({ data }) => data.getRelationshipDossierSharedIndustries);
};

const fetchSharedLinks = async ({
  client,
  source,
  target,
  page = 1,
  limit = 100,
}) => {
  return client
    .query({
      query: relationshipDossierLinks,
      variables: { sourceId: source, targetId: target, page, limit },
    })
    .then(({ data }) => data.getRelationshipDossierSharedLinks);
};

const DossierRequestData = ({ target, source, dossierType, onSizeChange }) => {
  const client = useApolloClient();
  const { connections } = useAxisData();
  const connectionData = connections.find((conn) => conn.entity.id === target);
  const { user } = useIdentity();
  const router = useRouteInformation();
  const [dossierRelationshipsHeader, setDossierRelationshipsHeader] =
    useState(null);
  const [isRelationshipsLoading, setRelationshipsLoading] = useState(false);

  const [dossierEvents, setDossierEvents] = useState([]);
  const [totalDossierEvents, setTotalDossierEvents] = useState(0);
  const [isEventsLoading, setEventsLoading] = useState(false);

  const [sharedRoles, setSharedRoles] = useState([]);
  const [totalSharedRoles, setTotalSharedRoles] = useState(0);
  const [isRoleLoading, setRolesLoading] = useState(false);

  const [sharedLocations, setSharedLocations] = useState([]);
  const [totalSharedLocations, setTotalSharedLocations] = useState(0);
  const [isLocationsLoading, setLocationsLoading] = useState(false);

  const [sharedIndustries, setSharedIndustries] = useState([]);
  const [totalSharedIndustries, setTotalSharedIndustries] = useState(0);
  const [isIndustriesLoading, setIndustriesLoading] = useState(false);

  const [sharedLinks, setSharedLinks] = useState([]);
  const [totalSharedLinks, setTotalSharedLinks] = useState(0);
  const [isLinksLoading, setLinksLoading] = useState(false);

  useEffect(() => {
    // Reset the data
    setDossierRelationshipsHeader(null);
    setRelationshipsLoading(false);

    setDossierEvents([]);
    setTotalDossierEvents(0);
    setEventsLoading(false);

    setSharedRoles([]);
    setTotalSharedRoles(0);
    setRolesLoading(false);

    setSharedLocations([]);
    setTotalSharedLocations(0);
    setLocationsLoading(false);

    setSharedIndustries([]);
    setTotalSharedIndustries(0);
    setIndustriesLoading(false);

    setSharedLinks([]);
    setTotalSharedLinks(0);
    setLinksLoading(false);

    // Fetch new data
    if (!source || !target) return undefined;
    let isCurrent = true;
    // ---[1/6] Fetch the linked entities
    setRelationshipsLoading(true);
    fetchDossierRelationshipHeader({ client, source, target })
      .then(({ sourceEntity, targetEntity, earliestDate, summary }) => {
        if (isCurrent)
          setDossierRelationshipsHeader({
            sourceEntity,
            targetEntity,
            earliestDate,
            summary,
          });
      })
      .finally(() => {
        if (isCurrent) setRelationshipsLoading(false);
      });

    // ---[2/6] Fetch the new events
    setEventsLoading(true);
    fetchRelationshipEvents({ client, source, target })
      .then(({ sharedEvents, totalSharedEvents }) => {
        if (!isCurrent) return;
        setTotalDossierEvents(totalSharedEvents);
        setDossierEvents(sharedEvents);
      })
      .finally(() => {
        if (isCurrent) setEventsLoading(false);
      });

    // ---[3/6] Fetch the new shared roles
    setRolesLoading(true);
    fetchSharedRoles({ client, source, target })
      .then(({ sharedRoles: roles, totalSharedRoles: total }) => {
        if (!isCurrent) return;
        setTotalSharedRoles(total);
        setSharedRoles(roles);
      })
      .finally(() => {
        if (isCurrent) setRolesLoading(false);
      });

    // ---[4/6] Fetch the new shared locations
    setLocationsLoading(true);
    fetchSharedLocations({ client, source, target })
      .then(({ sharedLocations: locations, totalSharedLocations: total }) => {
        if (!isCurrent) return;
        setTotalSharedLocations(total);
        setSharedLocations(locations);
      })
      .finally(() => {
        if (isCurrent) setLocationsLoading(false);
      });

    // ---[5/6] Fetch the new shared industries
    setIndustriesLoading(true);
    fetchSharedIndustries({ client, source, target })
      .then(
        ({ sharedIndustries: industries, totalSharedIndustries: total }) => {
          if (!isCurrent) return;
          setTotalSharedIndustries(total);
          setSharedIndustries(industries);
        },
      )
      .finally(() => {
        if (isCurrent) setIndustriesLoading(false);
      });

    // ---[6/6] Fetch the new shared links
    setLinksLoading(true);
    fetchSharedLinks({ client, source, target })
      .then(({ sharedLinks: links, totalSharedLinks: total }) => {
        if (!isCurrent) return;
        setTotalSharedLinks(total);
        setSharedLinks(links);
      })
      .finally(() => {
        if (isCurrent) setLinksLoading(false);
      });

    // All done! Return a cleanup function
    return () => {
      isCurrent = false;
    };
  }, [source, target]);

  const handleRequestMoreEvents = async () => {
    const page = Math.ceil(dossierEvents.length / 100) + 1;
    const { sharedEvents, totalSharedEvents } = await fetchRelationshipEvents({
      client,
      source,
      target,
      page,
    });
    setDossierEvents((currentEvents) => {
      const allEvents = currentEvents.concat(sharedEvents);
      // Remove duplicates
      const uniqueEvents = Array.from(
        new Map(allEvents.map((event) => [event.id, event])).values(),
      );
      // Sort by date
      uniqueEvents.sort((a, b) => new Date(b.date) - new Date(a.date));
      return uniqueEvents;
    });
    setTotalDossierEvents(totalSharedEvents);
  };

  const handleRequestMoreRoles = async () => {
    const page = Math.ceil(sharedRoles.length / 100) + 1;
    const { sharedRoles: roles, totalSharedRoles: total } =
      await fetchSharedRoles({
        client,
        source,
        target,
        page,
      });
    setSharedRoles((currentRoles) => currentRoles.concat(roles));
    setTotalSharedRoles(total);
  };

  const handleRequestMoreLocations = async () => {
    const page = Math.ceil(sharedLocations.length / 100) + 1;
    const { sharedLocations: locations, totalSharedLocations: total } =
      await fetchSharedLocations({
        client,
        source,
        target,
        page,
      });
    setSharedLocations((currentLocations) =>
      currentLocations.concat(locations),
    );
    setTotalSharedLocations(total);
  };

  const handleRequestMoreIndustries = async () => {
    const page = Math.ceil(sharedIndustries.length / 100) + 1;
    const { sharedIndustries: industries, totalSharedIndustries: total } =
      await fetchSharedIndustries({
        client,
        source,
        target,
        page,
      });
    setSharedIndustries((currentIndustries) =>
      currentIndustries.concat(industries),
    );
    setTotalSharedIndustries(total);
  };

  const handleRequestMoreLinks = async () => {
    const page = Math.ceil(sharedLinks.length / 100) + 1;
    const { sharedLinks: links, totalSharedLinks: total } =
      await fetchSharedLinks({
        client,
        source,
        target,
        page,
      });
    setSharedLinks((currentLinks) => currentLinks.concat(links));
    setTotalSharedLinks(total);
  };

  const onEntityPillClick = (entityId) => {
    router.openDossier({ dossierType: 'entity', dossierEntityId: entityId });
  };

  if (!dossierRelationshipsHeader) return null

  return  (
    <DossierHeader
      isLoading={isRelationshipsLoading}
      onSizeChange={onSizeChange}
    >
      {dossierType === 'link' && (
        <Dossier
          dossierRelationships={dossierRelationshipsHeader}
          dossierEvents={dossierEvents}
          totalDossierEvents={totalDossierEvents}
          isEventsLoading={isEventsLoading}
          onLoadMoreEvents={handleRequestMoreEvents}
          // Shared roles
          sharedRoles={sharedRoles}
          totalSharedRoles={totalSharedRoles}
          isRoleLoading={isRoleLoading}
          onLoadMoreRoles={handleRequestMoreRoles}
          // Shared locations
          sharedLocations={sharedLocations}
          totalSharedLocations={totalSharedLocations}
          isLocationsLoading={isLocationsLoading}
          onLoadMoreLocations={handleRequestMoreLocations}
          // Shared industries
          sharedIndustries={sharedIndustries}
          totalSharedIndustries={totalSharedIndustries}
          isIndustriesLoading={isIndustriesLoading}
          onLoadMoreIndustries={handleRequestMoreIndustries}
          // Shared links
          sharedLinks={sharedLinks}
          totalSharedLinks={totalSharedLinks}
          isLinksLoading={isLinksLoading}
          onLoadMoreLinks={handleRequestMoreLinks}
        />
      )}
      {dossierType === 'allConnections' && (
        <ConnectionCard
          connectionData={connectionData}
          userName={user.name}
          onEntityPillClick={onEntityPillClick}
        />
      )}
    </DossierHeader>
  );
};

export default DossierRequestData;
