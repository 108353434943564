import React, { useState } from 'react';
import css from './Body.module.css';
import Button from '../../../Buttom/Buttom';
import BookmarkButton from './BookmarkButton/BookMarkButton';
import ConnectButton from './ConnectButton/ConnectButton';
import { CountriesAndIndustries } from './Tags/Tags';
import AiBadge from '../../../AiBadge/AiBadge';
import ChevronDown from '../../../../../icons/ChevronDown';
import Activity from './Activity/Activity';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import AlterName from './AlterName/AlterName';
import Roles from './Roles/Roles';
import Education from './Education/Education';

function Body({
  entity,
  toggleBookmark,
  toggleUserConnection,
  isLoading,
  showNotesDossier,
}) {
  const [showMoreBio, setShowMoreBio] = useState(false);
  const [showMoreDevelopments, setShowMoreDevelopments] = useState(false);

  const showRoleEducationLine = Boolean(
    entity && (entity.roles?.length || entity.educations?.length),
  );

  const showInfo = Boolean(
    entity && (entity.lifespan || entity.alternateNames?.length),
  );

  if (isLoading) return null;

  if (!entity.inSubscription) return <OutOfSubscription />;

  const notesLabel = entity.notesCount < 2 ? 'Note' : 'Notes';
  const addOrShowLabel =
    entity.notesCount > 0 ? entity.notesCount.toString() : 'Add';

  return (
    <div className={css.main}>
      <div className={css.actions}>
        <BookmarkButton entity={entity} toggleBookmark={toggleBookmark} />

        <ConnectButton
          entity={entity}
          toggleConnection={toggleUserConnection}
        />

        <Button
          type="secondary"
          onClick={showNotesDossier}
          data-cy="dossier-add-note"
        >
          {addOrShowLabel} {notesLabel}
        </Button>
      </div>

      <CountriesAndIndustries
        countries={entity.countries}
        industries={entity.industries}
        primaryTopicId={entity.primaryTopicId}
      />

      {entity.bio && (
        <div className={css.bio}>
          <div className={css.bioTitle}>
            Biography <AiBadge />
          </div>
          <p className={css.bioContent} data-expanded={showMoreBio}>
            {entity.bio}
          </p>

          <button
            type="button"
            className={css.showMore}
            onClick={() => setShowMoreBio(!showMoreBio)}
            data-expanded={showMoreBio}
          >
            {showMoreBio ? 'Show Less' : 'Show More'}
            <ChevronDown className={css.chevronDown} />
          </button>
        </div>
      )}

      {entity.developments?.length > 0 && (
        <div className={css.bio}>
          <div className={css.bioTitle}>
            Recent Developments <AiBadge />
          </div>
          <ul className={css.bioContent} data-expanded={showMoreDevelopments}>
            {entity.developments.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>

          <button
            type="button"
            className={css.showMore}
            onClick={() => setShowMoreDevelopments(!showMoreDevelopments)}
            data-expanded={showMoreDevelopments}
          >
            {showMoreDevelopments ? 'Show Less' : 'Show More'}
            <ChevronDown className={css.chevronDown} />
          </button>
        </div>
      )}

      <Activity entity={entity} />

      <div className={css.cards}>
        {showInfo && <PersonalInformation entity={entity} />}
        {showInfo && <AlterName entity={entity} />}
        {showRoleEducationLine && <Roles entity={entity} />}
        {showRoleEducationLine && <Education entity={entity} />}
      </div>
    </div>
  );
}

export default Body;

function OutOfSubscription() {
  return (
    <p className={css.outsideSubscription}>
      This content is outside your subscription. Please
      <a href="mailto:contact@axis.xyz"> contact Sales</a> to gain access.
    </p>
  );
}
