import { gql } from '@apollo/client';

const query = gql`
  query searchEventsAndRegulations(
    $name: String
    $topics: [ID]
    $filterIndustries: [ID]
    $filterEntities: [ID]
    $filterCategories: FilterCategories
    $startDate: String
    $endDate: String
    $bookmarked: Boolean
    $connected: Boolean
    $notes: Boolean
  ) {
    searchEventsAndRegulations(
      name: $name
      topics: $topics
      filterIndustries: $filterIndustries
      filterEntities: $filterEntities
      filterCategories: $filterCategories
      startDate: $startDate
      endDate: $endDate
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
    ) {
      events {
        nextToken
        limit
        results {
          ... on SearchAppointment {
            id
            title
            headline
            startDate
            magnitude
            sentiment
            entity {
              id
              name
              shortestName
              image
              type
              inSubscription
              isConnected
              tooltip
            }
          }
        }
      }
    }
  }
`;

export default query;
