import { gql } from '@apollo/client';

const query = gql`
  query getRegulation($id: ID!) {
    getRegulation(id: $id) {
      regulation {
        id
        legalName
        regulationType
        summarizedName
        bullets
        isCompliant
        source
        summary
        createdAt
        startDate
        jurisdiction
        attachment
        issuer {
          id
          name
          shortestName
          image
          type
          isConnected
          inSubscription
          tooltip
        }
        authorizedIssuer {
          id
          name
          image
          tooltip
          type
          inSubscription
          isConnected
        }
        industries {
          id
          name
          tooltip
        }
      }
    }
  }
`;
export default query;
