export const ROUTES = {
  ROOT: '/',
  FEED: '/feed',
  ADV_SEARCH: '/search',
  LOGIN: '/login',
  PROFILE: '/profile',
  NETWORK: '/network',
  AI_SEARCH: '/ai-search',
  PDB: '/pdb',
  SCATTER_PLOT: '/scatter-plot-events',
};
