import { gql } from '@apollo/client';

export const SEMANTIC_SEARCH = gql`
  subscription SemanticSearch($question: String!) {
    semanticSearch(question: $question) {
      text
      endOfStream
      results {
        actors {
          id
          name
          type
          subType
          shortestName
          image
          inSubscription
          isConnected
        }
        events {
          id
          title
          source
          date
        }
        regulations {
          id
          legal_name
          summarized_name
          source
          date
        }
        locations
        industries
        followUpQuestions
      }
    }
  }
`;
